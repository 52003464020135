import { nanoid } from "@reduxjs/toolkit";
import { ChangeEventHandler, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../../components/Modal";
import Option from "../../components/Option";
import CredentialForm from "../../components/admin/CredentialForm";
import { FormInput } from "../../components/custom/components";
import {
  Tbody,
  Thead,
} from "../../components/custom/components/AdminTable";
import AdminLayout from "../../components/custom/layouts/AdminLayout";
import { ToastTypes, useToast } from "../../components/toast/ToastProvider";
import { FormType, FormTypeList } from "../../config";
import { today } from "../../config/functions";
import { BrandType } from "../../redux/actions/brandActions";
import {
  BuildingType,
  fetchBuilding,
} from "../../redux/actions/buildingActions";
import {
  ActivateCredential,
  CredentialType,
  DeActivateCredential,
  deleteCredential,
  fetchCredential,
} from "../../redux/actions/credentialActions";
import { FloorType, fetchFloor } from "../../redux/actions/floorActions";
import { PortfolioType } from "../../redux/actions/portfolioActions";
import { AppDispatch, StoreState } from "../../redux/store";
import DeleteConfirmationForm from "../../components/admin/DeleteConfirmationForm";
import { useLocation, useSearchParams } from "react-router-dom";
import AdminTable2 from "../../components/custom/components/AdminTable2";
import { AuthAdminType } from "../../redux/actions/authAdminActions";
import { AdminLog } from "../../config/common";

type HandleDelete = (value: CredentialType) => void;

const CredentialManager = () => {
  const toast = useToast();
  const dispatch = useDispatch<AppDispatch>();
  const pageName = useLocation()
  const authAdmin = useSelector<StoreState, AuthAdminType | null>(
    (state) => state.authAdmin
  );
  const adminAuthendicated =
  typeof authAdmin?.["auth-token"] === "string" &&
  authAdmin?.["auth-token"] !== undefined &&
  authAdmin?.["auth-token"] !== "";
  const CredentialList = useSelector<StoreState, Array<CredentialType>>(
    (state) => state.credential
  );
  const brandList = useSelector<StoreState, Array<BrandType>>(
    (state) => state.brand
  );
  const portfolioList = useSelector<StoreState, Array<PortfolioType>>(
    (state) => state.portfolio
  );
  const buildingList = useSelector<StoreState, Array<BuildingType>>(
    (state) => state.building
  );
  const FloorList = useSelector<StoreState, Array<FloorType>>(
    (state) => state.floor
  );

  const mainContentRef = useRef<HTMLDivElement>(null);
  const searchRef = useRef<HTMLInputElement>(null);
  const [searchQuery, setSearchQuery] = useSearchParams();
  const searchTerm = searchQuery.get("search") || "";

  const [showForm, setShowForm] = useState<boolean>(false);
  const [showDeactiveForm, setShowDeactiveForm] = useState<boolean>(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] =
    useState<boolean>(false);
  const [formType, setFormType] = useState<FormType>(FormTypeList.ADD);
  const [editData, setEditData] = useState<CredentialType>();
  const [showViewForm, setShowViewForm] = useState<boolean>(false);
  const [deleteData, setDeleteData] = useState<CredentialType>();
  const [id, setId] = useState<number>();
  const [portfolioId, setportfolioId] = useState<string>("");
  const [portfolioIdError, setportfolioIdError] = useState<string>("");

  const [deactivationDate, setDeactivationDate] = useState<string>(today());
  const [deactivationDateError, setDeactivationDateError] =
    useState<string>("");
  const [reason, setReason] = useState<string>("");
  const [reasonError, setReasonError] = useState<string>("");

  const [credData, setCredData] = useState<CredentialType[]>([]);

  const limit = 20;
  const [offset, setOffset] = useState<number>(0);
  const [range, setRange] = useState<number[]>([]);
  const [length, setLength] = useState<number>(0);

  const handleApply = async (offsetVal = offset) => {
    if (portfolioId) {
      if (CredentialList?.length > 0) {
        const getData = {
          limit: limit?.toString(),
          offset: offsetVal?.toString(),
        };

        if (
          getData !== undefined &&
          getData.limit !== "" &&
          getData.offset !== "" &&
          getData.offset !== undefined &&
          getData.offset !== "NaN"
        ) {
          const filteredCredList: CredentialType[] = CredentialList?.slice(
            offset,
            limit + offset
          );
          if (filteredCredList?.length > 0) {
            setCredData([...filteredCredList]);
          } else {
            setCredData([]);
          }
        }
      }
    }
  };

  const getTableData = () => {
    var tableHead: Thead[] = [
      { id: "S.No", children: "S.No", text: "S.No" },
      { id: "Display ID", children: "Display ID", text: "Display ID" },
      { id: "Brand", children: "Brand", text: "Brand" },
      { id: "Location", children: "Location", text: "Location" },
      // { id: 'Lounge', children: 'Lounge', text: 'Lounge' },
      // { id: 'forex', children: 'forex', text: 'forex' },
      // { id: 'POS Terminals', children: 'POS Terminals', text: 'POS Terminals' },
      { id: "Capture Mode", children: "Capture Mode", text: "Capture Mode" },
      { id: "Trans Mode", children: "Trans Mode", text: "Trans Mode" },
      // { id: 'Data Pull', children: 'Data Pull', text: 'Data Pull' },
      // { id: 'DI Service Name', children: 'DI Service Name', text: 'DI Service Name' },
      // { id: 'Version', children: 'Version', text: 'Version' },
      // { id: 'Released On', children: 'Released On', text: 'Released On' },
      // { id: 'Frequency', children: 'Frequency', text: 'Frequency' },
      // { id: 'Revenue Data', children: 'Revenue Data', text: 'Revenue Data' },
      { id: "Status", children: "Status", text: "Status" },
      { id: "Action", children: "Action", text: "Action" },
    ];
    var tableBody: Tbody[][] = [];

    if (portfolioId !== "") {
      credData
        .filter((cr) => searchCredetialHandler(cr, searchTerm))
        .forEach((item, i) => {
          let index = brandList.findIndex((c, i) => {
            return c.id === item.brandid;
          });
          var bcname = brandList[index]?.name;

          let obj = FloorList.find((f) => f.id === item.floorid);
          var fname = obj?.floorname;

          let obj2 = buildingList.find((f) => f.id === item.buildingid);
          var bname = obj2?.buildingname;

          const pageNumber = Math.floor(offset / limit) + 1;

          var tBody: Tbody[] = [];
          tBody.push(
            {
              id: nanoid(),
              theadId: "S.No",
              children: limit * pageNumber + i - limit + 1,
              text: (limit * pageNumber + i - limit + 1)?.toString(),
            },
            {
              id: nanoid(),
              theadId: "Display ID",
              children: item.displayid,
              text: item.displayid,
            },
            {
              id: nanoid(),
              theadId: "Brand",
              children: bcname,
              text: bcname,
            },
            {
              id: nanoid(),
              theadId: "Location",
              children: bname + "-" + fname,
              text: bname + "-" + fname,
              className: "text-truncate",
            },
            {
              id: nanoid(),
              theadId: "Capture Mode",
              children: item.capturemode,
              text: item.capturemode,
            },
            {
              id: nanoid(),
              theadId: "Trans Mode",
              children: item.datatransmode,
              text: item.datatransmode,
            },
            {
              id: nanoid(),
              theadId: "Status",
              children: item.status,
              text: item.status,
            },
            {
              id: nanoid(),
              theadId: "Action",
              children: "action",
              className: "d-none",
              text: item.tenantid?.toString(),
            }
          );
          tableBody.push(tBody);
          // })
        });
    }

    return { tbody: tableBody, thead: tableHead };
  };

 // const handleAdd = () => {
  //  setShowForm(true);
 // };

  useEffect(() => {
    if (searchTerm !== "") {
      searchRef.current?.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleActivate = (id: number) => {
    if (id !== undefined && portfolioId !== undefined) {
      const data = {
        portfolioid: portfolioId,
        active: true,
        status: "Reactivated",
      };
      dispatch(ActivateCredential(data, id))
        .then((text) => {
          toast(text, ToastTypes.SUCCESS);
          dispatch(fetchCredential(portfolioId));
          handleFormClose();
        })
        .catch((text) => {
          toast(text, ToastTypes.ERROR);
        });
    }
  };
  const handleDeActivate = () => {
    if (id !== undefined && portfolioId !== undefined) {
      const data = {
        portfolioid: portfolioId,
        active: false,
        status: "Deactivated",
        deactivationdate: deactivationDate,
        reason: reason,
      };
      dispatch(DeActivateCredential(data, id))
        .then((text) => {
          toast(text, ToastTypes.SUCCESS);
          dispatch(fetchCredential(portfolioId));
          handleFormClose();
        })
        .catch((text) => {
          toast(text, ToastTypes.ERROR);
        });
    }
  };
  const handleDeActivateClick = () => {
    setShowDeactiveForm(true);
  };
  const handlePfid: ChangeEventHandler<HTMLInputElement> = (e) => {
    setportfolioId(e.target.value);
    setportfolioIdError("");
  };

  const handleDeactivationDate: ChangeEventHandler<HTMLInputElement> = (e) => {
    const { value } = e.target;
    var today = new Date();
    var d1 = today.getDate();
    var m1 = today.getMonth() + 1;
    var y1 = today.getFullYear();

    const liveFromDate = new Date(value);
    var d2 = liveFromDate.getDate();
    var m2 = liveFromDate.getMonth() + 1;
    var y2 = liveFromDate.getFullYear();

    if (m1 === m2 && d1 < d2) return false;
    else if (m1 < m2) return false;
    else if (y1 < y2) return false;
    else {
      setDeactivationDate(value);
      setDeactivationDateError("");
    }
  };

  const handleReason: ChangeEventHandler<HTMLInputElement> = (e) => {
    setReason(e.target.value);
    setReasonError("");
  };

  const searchCredetialHandler = (
    credential: CredentialType,
    searchTerm: string
  ) => {
    return searchTerm !== ""
      ? new RegExp(`^${searchTerm}|${searchTerm}`, "gi").test(
          credential.displayid + " " + credential.brandname
        )
      : true || [];
  };

  const searchHandler: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (!portfolioId) {
      toast("Porfolio required", ToastTypes.ERROR);
      return;
    }
    setSearchQuery({ search: e.target.value });
  };

  const handleView = () => {
    setFormType(FormTypeList.VIEW);
    if (id !== undefined) {
      var obj = CredentialList.find((c) => c.tenantid === id);
      setShowForm(true);
      setEditData(obj);
      setShowViewForm(true);
      if(adminAuthendicated){
        const logProps1 = {
          action: 'View',
          event: `${pageName.pathname.replace("\\", " ")} page | Credential Manager - ${obj?.displayid} | View Clicked`
        };
        AdminLog(logProps1)
      }
    }
  };

  const handleDeleteConfirmation = () => {
    setFormType(FormTypeList.DELETE);
    if (id !== undefined) {
      var obj = CredentialList.find((c) => c.tenantid === id);
      setShowDeleteConfirmation(true);
      setDeleteData(obj);
      if(adminAuthendicated){
        const logProps1 = {
          action: 'Edit',
          event: `${pageName.pathname.replace("\\", " ")} page | Credential Manager - ${obj?.displayid} | Edit Clicked`
        };
        AdminLog(logProps1)
      }
    }
  };

  const handleEdit = () => {
    setFormType(FormTypeList.EDIT);
    if (id !== undefined) {
      var obj = CredentialList.find((c) => c.tenantid === id);
      setEditData(obj);
      setShowForm(true);
    }
  };

  const handleDelete: HandleDelete = (value) => {
    setFormType(FormTypeList.DELETE);
    if (id !== undefined) {
      dispatch(deleteCredential(id, portfolioId))
        .then(async (text) => {
          dispatch(fetchCredential(portfolioId));
          toast(text, ToastTypes.SUCCESS);
        
          if (credData !== undefined && credData?.length === 1) {
            let newOffset = offset - limit < 0 ? 0 : offset - limit;
            setOffset(newOffset);
            await handleApply(newOffset);
          } else {
            await handleApply(); //issue starts from here
          }
          handleFormClose();
        })
        .catch((text) => {});
    }
  };

  const handleFormClose = () => {
    setShowForm(false);
    setShowDeactiveForm(false);
    setFormType(FormTypeList.ADD);
    setEditData(undefined);
    setId(undefined);
    setShowViewForm(false);
    setDeactivationDate(today());
    setDeactivationDateError("");
    setReason("");
    setShowDeleteConfirmation(false);
    setReasonError("");
  };

  useEffect(() => {
    if (portfolioId) {
      if (CredentialList?.length > 0) {
        setLength(CredentialList?.length ? CredentialList?.length : 0);
      }
    }
  }, [CredentialList, portfolioId]);

  useEffect(() => {
    if (id !== undefined && formType === FormTypeList.VIEW) handleView();
    else if (id !== undefined && formType === FormTypeList.EDIT) handleEdit();
    else if (id !== undefined && formType === FormTypeList.DELETE)
      handleDeleteConfirmation();
  }, [id, formType]);

  useEffect(() => {
    if (portfolioId) {
      dispatch(fetchFloor(portfolioId));
      dispatch(fetchBuilding(portfolioId));
      dispatch(fetchCredential(portfolioId));
    }
  }, [portfolioId]);

  useEffect(() => {
    if (portfolioId) handleApply();
  }, [CredentialList, portfolioId]);

  useEffect(() => {
    var range = [];
    var num = Math.ceil(length / limit);
    // let i = 1;

    for (let i = 1; i <= num; i++) {
      range.push(i);
    }

    setRange(range);
  }, [length, limit]);

  useEffect(()=>{
    if(adminAuthendicated){
    const logProps1 = {
      action:`Visit`,
      event: `${pageName.pathname.replace("\\", " ")} page | Visited `
    };
    AdminLog(logProps1)
  }
  },[])

  return (
    <>
      <Modal
        headerText={formType + " CREDENTIAL"}
        visible={showForm}
        onClose={handleFormClose}
        size="xl"
        centered
      >
        <CredentialForm
          formType={formType}
          editData={editData}
          onSave={handleFormClose}
          showViewForm={showViewForm}
          pfId={portfolioId}
        />
      </Modal>
      <Modal
        headerText={"Delete Role"}
        visible={showDeleteConfirmation}
        onClose={handleFormClose}
        size="sm"
        centered
      >
        <DeleteConfirmationForm
          deleteLabel={
            "Do you really want to delete the records with the credential - " +
            deleteData?.displayid
          }
          // deleteData={deleteData!}
          onCancel={handleFormClose}
          onDelete={() => handleDelete(deleteData!)}
        />
      </Modal>
      <Modal
        headerText={"CREDENTIAL"}
        visible={showDeactiveForm}
        onClose={handleFormClose}
        size="sm"
        centered
      >
        <div className="col-12">
          <FormInput
            name="Deactivation Date"
            label="Deactivation Date"
            labelClassName="required"
            value={deactivationDate}
            onChange={handleDeactivationDate}
            placeholder=" Deactivation Date"
            errorText={deactivationDateError}
            containerClass="mb-2"
            type="date"
            max={today()}
          />
        </div>
        <div className="col-12">
          <FormInput
            name="Reason"
            label="Reason"
            labelClassName="required"
            value={reason}
            onChange={handleReason}
            placeholder="Reason"
            errorText={reasonError}
            containerClass="mb-2"
          />
        </div>
        <button className="btn btn-sm btn-success" onClick={handleDeActivate}>
          Save
        </button>
      </Modal>
      <AdminLayout>
        <div className="row">
          <div className="col-6 hstack mt-4">
            <label htmlFor="">Portfolio :</label>
            <FormInput
              name="Portfolio"
              label=""
              labelClassName="required"
              value={portfolioId}
              onChange={handlePfid}
              placeholder="Portfolio"
              errorText={portfolioIdError}
              containerClass="w-50"
              disabled={showViewForm}
              type="select"
            >
              <option value="">Select</option>
              {portfolioList.map((a, i) => {
                return (
                  <Option value={a.id} key={i}>
                    {a.name}
                  </Option>
                );
              })}
            </FormInput>
          </div>
        </div>
        <AdminTable2
          addBtnText="Create New Credential"
          // onAdd={handleAdd}
          onEdit={handleEdit}
          // onDelete={handleDeleteConfirmation}
          onView={handleView}
          search={searchHandler}
          limit={limit}
          setOffset={setOffset}
          offset={offset}
          onPageNoChange={handleApply}
          range={range}
          searchRef={searchRef}
          searchTerm={searchTerm}
          mainContentRef={mainContentRef}
          pageTitle="Credential Manager"
          path="/admin/credential_manager"
          // data={data}
          setId={setId}
          {...getTableData()}
          onActivate={handleActivate}
          onDeActivate={handleDeActivateClick}
        />
      </AdminLayout>
    </>
  );
};

export default CredentialManager;
