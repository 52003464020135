import { nanoid } from "@reduxjs/toolkit";
import { ChangeEventHandler, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../../components/Modal";
import Option from "../../components/Option";
import BuildingForm from "../../components/admin/BuildingForm";
import { FormInput } from "../../components/custom/components";
import  {
  Tbody,
  Thead,
} from "../../components/custom/components/AdminTable";
import AdminLayout from "../../components/custom/layouts/AdminLayout";
import { ToastTypes, useToast } from "../../components/toast/ToastProvider";
import { FormType, FormTypeList } from "../../config";
import {
  BuildingType,
  deleteBuilding,
  fetchBuilding,
} from "../../redux/actions/buildingActions";
import { PortfolioType } from "../../redux/actions/portfolioActions";
import { SiteType } from "../../redux/actions/siteActions";
import { AppDispatch, StoreState } from "../../redux/store";
import DeleteConfirmationForm from "../../components/admin/DeleteConfirmationForm";
// import { FloorType, fetchFloor } from "../../redux/actions/floorActions";
import api, { getAxiosRequestConfig } from "../../config/api";
import { useLocation, useSearchParams } from "react-router-dom";
import AdminTable2 from "../../components/custom/components/AdminTable2";
import { AdminLog} from "../../config/common"
import { AuthAdminType } from "../../redux/actions/authAdminActions";

type HandleDelete = (value: BuildingType) => void;

const BuildingManagement = () => {
  const toast = useToast();
  const dispatch = useDispatch<AppDispatch>();
  const pageName = useLocation()
  const authAdmin = useSelector<StoreState, AuthAdminType | null>(
    (state) => state.authAdmin
  );
  const adminAuthendicated =
  typeof authAdmin?.["auth-token"] === "string" &&
  authAdmin?.["auth-token"] !== undefined &&
  authAdmin?.["auth-token"] !== "";
  const authAdminToken = useSelector<StoreState, AuthAdminType | null>(
    (state) => state.authAdmin
  )?.["auth-token"]!;
  const BuildingList = useSelector<StoreState, Array<BuildingType>>(
    (state) => state.building
  );
  const siteList = useSelector<StoreState, Array<SiteType>>(
    (state) => state.site
  );
  const portfolioList = useSelector<StoreState, Array<PortfolioType>>(
    (state) => state.portfolio
  );

  const filteredPortfolio = portfolioList.filter((a) => a.businessmodel !== 1);
  const searchRef = useRef<HTMLInputElement>(null);
  const [showForm, setShowForm] = useState<boolean>(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] =
    useState<boolean>(false);
  const [formType, setFormType] = useState<FormType>(FormTypeList.ADD);
  const [editData, setEditData] = useState<BuildingType>();
  const [deleteData, setDeleteData] = useState<BuildingType>();
  const [showViewForm, setShowViewForm] = useState<boolean>(false);
  const [disableDelete, setDisableDelete] = useState<boolean>(false);
  const [id, setId] = useState<number>();

  const [portfolioId, setportfolioId] = useState<string>("");
  const [portfolioIdError, setportfolioIdError] = useState<string>("");

  const [searchQuery, setSearchQuery] = useSearchParams();
  const searchTerm = searchQuery.get("search") || "";

  const [buildingData, setBuildingData] = useState<BuildingType[]>([]);

  const limit = 20;
  const [offset, setOffset] = useState<number>(0);
  const [range, setRange] = useState<number[]>([]);
  const [length, setLength] = useState<number>(0);

  const searchHandler: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (!portfolioId) {
      toast("Porfolio required", ToastTypes.ERROR);
      setSearchQuery({ search: "" });
      return;
    }
    setSearchQuery({ search: e.target.value });
  };

  const searchBuildingHandler = (item: any, searchTerm: string) => {
    return searchTerm !== ""
      ? new RegExp(`^${searchTerm}|${searchTerm}`, "gi").test(
          item[1]["text"] + " " + item[2]["text"]
        )
      : true || [];
  };

  const handleApply = async (offsetVal = offset) => {
    if (portfolioId) {
      if (BuildingList?.length > 0) {
        const getData = {
          limit: limit?.toString(),
          offset: offsetVal?.toString(),
        };

        if (
          getData !== undefined &&
          getData.limit !== "" &&
          getData.offset !== "" &&
          getData.offset !== undefined &&
          getData.offset !== "NaN"
        ) {
          const filteredBuildingList: BuildingType[] = BuildingList?.slice(
            offset,
            limit + offset
          );
          if (filteredBuildingList?.length > 0) {
            setBuildingData([...filteredBuildingList]);
          } else {
            setBuildingData([]);
          }
        }
      }
    }
  };

  const getTableData = () => {
    if (buildingData === undefined) {
      return { tbody: [], thead: [] };
    }
    var tableHead: Thead[] = [
      { id: "S.No", children: "S.No", text: "S.No" },
      { id: "Center Name", children: "Center Name", text: "Center Name" },
      { id: "Building Name", children: "Building Name", text: "Building Name" },
      { id: "Code", children: "Code", text: "Code" },
      { id: "Action", children: "Action", text: "Action" },
    ];
    var tableBody: Tbody[][] = [];
    var tableBodyFiltered: Tbody[][] = [];

    if (portfolioId) {
      buildingData.forEach((item, i) => {
        let index = siteList.findIndex((c, i) => {
          return c.id === item.centerid;
        });
        var bcname = siteList[index]?.name;
        const pageNumber = Math.floor(offset / limit) + 1;

        var tBody: Tbody[] = [];
        tBody.push(
          {
            id: nanoid(),
            theadId: "S.No",
            children: limit * pageNumber + i - limit + 1,
            text: (limit * pageNumber + i - limit + 1)?.toString(),
          },
          {
            id: nanoid(),
            theadId: "Center Name",
            children: bcname,
            text: bcname,
            className: "text-truncate",
          },
          {
            id: nanoid(),
            theadId: "Building Name",
            children: item.buildingname,
            text: item.buildingname,
            className: "text-truncate",
          },
          {
            id: nanoid(),
            theadId: "Code",
            children: item.code,
            text: item.code,
          },
          {
            id: nanoid(),
            theadId: "Action",
            children: "action",
            className: "d-none",
            text: item.id?.toString(),
          }
        );
        tableBody.push(tBody);
        // })
      });
      if (searchTerm?.length > 0) {
        tableBodyFiltered = tableBody.filter((item) =>
          searchBuildingHandler(item, searchTerm)
        );
      } else {
        tableBodyFiltered = tableBody;
      }

      if (buildingData !== undefined) {
        return { tbody: tableBodyFiltered, thead: tableHead };
      }
    }
    return { tbody: tableBody, thead: tableHead };
  };

  const handleAdd = () => {
    if (!portfolioId) toast("Portfolio required", ToastTypes.ERROR);
    else {
      setShowForm(true)
      if(adminAuthendicated){
        const logProps1 = {
          action: 'Create',
          event: `${pageName.pathname.replace("\\", " ")} page | Create Building Clicked`
        };
        AdminLog(logProps1)
      }
    };
  };

  const handlePfid: ChangeEventHandler<HTMLInputElement> = (e) => {
    setportfolioId(e.target.value);
    setportfolioIdError("");
  };

  const handleView = () => {
    setFormType(FormTypeList.VIEW);
    if (id !== undefined) {
      var obj = BuildingList.find((c) => c.id === id);
      var centerObj = siteList.find((d)=>d.id===obj?.centerid)
      setShowForm(true);
      setEditData(obj);
      setShowViewForm(true);
      if(adminAuthendicated){
        const logProps1 = {
          action: 'View',
          event: `${pageName.pathname.replace("\\", " ")} page | Center - ${centerObj?.name}, Building - ${obj?.buildingname} Viewed | View Clicked`
        };
        AdminLog(logProps1)
      }
    }
  };

  const handleDeleteConfirmation = async () => {
    setFormType(FormTypeList.DELETE);
    if (id !== undefined) {
      var obj = BuildingList.find((c) => c.id === id);
      setShowDeleteConfirmation(true);
      setDeleteData(obj);
      const token = authAdminToken;
      const config = getAxiosRequestConfig(token);
      const {
        data: { data },
      } = await api.get(`floor/search/${portfolioId}/${obj?.id}`, config);
      setDisableDelete(data && data?.length > 0 ? true : false);
    }
  };

  const handleEdit = () => {
    setFormType(FormTypeList.EDIT);
    if (id !== undefined) {
      var obj = BuildingList.find((c) => c.id === id);
      var centerObj = siteList.find((d)=>d.id===obj?.centerid)
      setEditData(obj);
      setShowForm(true);
      if(adminAuthendicated){
        const logProps1 = {
          action: 'Edit',
          event: `${pageName.pathname.replace("\\", " ")} page | Center - ${centerObj?.name}, Building - ${obj?.buildingname} | Edit Clicked`
        };
        AdminLog(logProps1)
      }
    }
  };

  const handleDelete: HandleDelete = (value) => {
    if (value.id !== undefined) {
      dispatch(deleteBuilding(value.id, portfolioId))
        .then(async (text) => {
          dispatch(fetchBuilding(portfolioId));
          toast(text, ToastTypes.SUCCESS);
          var obj = BuildingList.find((c) => c.id === id);
         
          if(adminAuthendicated){
            const logProps1 = {
              action: 'Delete',
              event: `${pageName.pathname.replace("\\", " ")} page | Building - ${obj?.buildingname} | Delete Clicked`
            };
            AdminLog(logProps1)
          }
          if (buildingData !== undefined && buildingData?.length === 1) {
            let newOffset = offset - limit < 0 ? 0 : offset - limit;
            setOffset(newOffset);
            await handleApply(newOffset);
          } else {
            await handleApply(); //issue starts from here
          }
          handleFormClose();
        })
        .catch((text) => {});
    }
  };

  const handleFormClose = () => {
    setShowForm(false);
    setFormType(FormTypeList.ADD);
    setEditData(undefined);
    setId(undefined);
    setShowViewForm(false);
    setShowDeleteConfirmation(false);
  };

  useEffect(() => {
    if (portfolioId) {
      if (BuildingList?.length > 0) {
        setLength(BuildingList?.length ? BuildingList?.length : 0);
      }
    }
  }, [BuildingList, portfolioId]);

  useEffect(() => {
    if (id !== undefined && formType === FormTypeList.VIEW) handleView();
    else if (id !== undefined && formType === FormTypeList.EDIT) handleEdit();
    else if (id !== undefined && formType === FormTypeList.DELETE)
      handleDeleteConfirmation();
  }, [id, formType]);

  useEffect(() => {
    if (portfolioId) {
      dispatch(fetchBuilding(portfolioId));
    }
  }, [portfolioId]);

  useEffect(() => {
    if (searchTerm !== "") {
      searchRef.current?.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (portfolioId) handleApply();
  }, [BuildingList, portfolioId]);

  useEffect(() => {
    var range = [];
    var num = Math.ceil(length / limit);
    // let i = 1;

    for (let i = 1; i <= num; i++) {
      range.push(i);
    }

    setRange(range);
  }, [length, limit]);

  useEffect(()=>{
    if(adminAuthendicated){
    const logProps1 = {
      action:`Visit`,
      event: `${pageName.pathname.replace("\\", " ")} page | Visited `
    };
    AdminLog(logProps1)
  }
  },[])

  return (
    <>
      <Modal
        headerText={formType + " BUILDING"}
        visible={showForm}
        onClose={handleFormClose}
        size="xl"
        centered
      >
        <BuildingForm
          formType={formType}
          editData={editData}
          onSave={handleFormClose}
          showViewForm={showViewForm}
          pfId={portfolioId}
        />
      </Modal>
      <Modal
        headerText={formType + " BUILDING"}
        visible={showDeleteConfirmation}
        onClose={handleFormClose}
        size="lg"
        centered
      >
        <DeleteConfirmationForm
          deleteLabel={
            disableDelete
              ? "Center is in use. Deletion not allowed."
              : "Do you really want to delete the records with the building - " +
                deleteData?.buildingname
          }
          onCancel={handleFormClose}
          onDelete={() => (disableDelete ? {} : handleDelete(deleteData!))}
        />
      </Modal>
      <AdminLayout>
        <div className="row">
          <div className="col-6 hstack mt-4">
            <label htmlFor="">Portfolio :</label>
            <FormInput
              name="Portfolio"
              label=""
              labelClassName="required"
              value={portfolioId}
              onChange={handlePfid}
              placeholder="Portfolio"
              errorText={portfolioIdError}
              containerClass="w-50"
              disabled={showViewForm}
              type="select"
            >
              <option value="">Select</option>
              {filteredPortfolio.map((a, i) => {
                return (
                  <Option value={a.id} key={i}>
                    {a.name}
                  </Option>
                );
              })}
            </FormInput>
          </div>
        </div>
        <AdminTable2
          addBtnText="Create New Building"
          onAdd={handleAdd}
          onEdit={handleEdit}
          onDelete={handleDeleteConfirmation}
          onView={handleView}
          search={
            portfolioId !== "" && portfolioId !== undefined
              ? searchHandler
              : undefined
          }
          limit={limit}
          setOffset={setOffset}
          offset={offset}
          onPageNoChange={handleApply}
          range={range}
          pageTitle="Building Management"
          path="/admin/building_management"
          // data={data}
          setId={setId}
          {...getTableData()}
        />
      </AdminLayout>
    </>
  );
};

export default BuildingManagement;
