import React, { ChangeEventHandler, useState } from "react"
import { TenantContactDetails, TenantManagerContext, TenantManagerContextType } from "../context/TenantManagerContext"
import { FormInput } from "../custom/components"
import { testEmail, testNumber } from "../../config/functions"

const ContactForm = () => {
    const { tenantContactDetails, setTenantContactDetails
    } = React.useContext<TenantManagerContextType | null>(TenantManagerContext)!

    const [name, setName] = useState<string>('')
    const [nameError, setNameError] = useState<string>('')
    // const [lastName, setLastName] = useState<string>('')
    // const [lastNameError, setLastNameError] = useState<string>('')
    const [designation, setDesignation] = useState<string>('')
    const [designationError, setDesignationError] = useState<string>('')
    const [email, setEmail] = useState<string>('')
    const [emailError, setEmailError] = useState<string>('')
    const [contactNo, setContactNo] = useState<string>('')
    const [contactNoError, setContactNoError] = useState<string>('')

    const handleName: ChangeEventHandler<HTMLInputElement> = (e) => {
        setName(e.target.value)
        setNameError('')
    }
    // const handleLastName: ChangeEventHandler<HTMLInputElement> = (e) => {
    //     setLastName(e.target.value)
    //     setLastNameError('')
    // }
    const handleDesignation: ChangeEventHandler<HTMLInputElement> = (e) => {
        setDesignation(e.target.value)
        setDesignationError('')
    }
    const handleEmail: ChangeEventHandler<HTMLInputElement> = (e) => {
        setEmail(e.target.value)
        setEmailError('')
    }
    const handleContactNo: ChangeEventHandler<HTMLInputElement> = (e) => {
        if((!testNumber(e.target.value)&&e.target.value))
        return
        else if(e.target.value?.length>=12)
        return
    
        setContactNo(e.target.value)
        setContactNoError('')
    }

    const handleRemoveItem = (value: TenantContactDetails) => {
        setTenantContactDetails!(prev => {
            return prev.filter(p => p.tenantName !== value.tenantName)
        })
        // setToListError('')
    }

    const handleSave = () => {
        let error = false
        const data: TenantContactDetails = {
            tenantName: name,
            tenantDesignation: designation,
            tenantEmail: email,
            tenantContactNo: contactNo
        }

        if (data.tenantName === '') {
            setNameError('Name required')
            error = true
        } else if ((data.tenantName)?.length > 50) {
            setNameError('Name should be within 50 digits')
            error = true
        }

        if (data.tenantDesignation === '') {
            setDesignationError('Designation required')
            error = true
        } else if ((data.tenantDesignation)?.length > 50) {
            setDesignationError('Designation should be within 50 digits')
            error = true
        }

        if (data.tenantEmail === '') {
            setEmailError('Email required')
            error = true
        } else if ((data.tenantEmail)?.length > 35) {
            setEmailError('Email should be within 30 characters')
            error = true
        }
        else if(data.tenantEmail&&!testEmail(data.tenantEmail)) {
            setEmailError('Email-id is not valid')
            error = true
        }

        if (data.tenantContactNo === '') {
            setContactNoError('Contact No required')
            error = true
        } else if ((data.tenantContactNo)?.length > 15) {
            setContactNoError('Contact No should be within 15 characters')
            error = true
        }else if (data.tenantContactNo&&!testNumber(data.tenantContactNo)) {
            setContactNoError('Phone Number must be a number')
            error = true
        }

        let namesArray = tenantContactDetails?.map(t => t.tenantName)
        if (!error) {
            // const handleToList = (value: string) => {
            if (!namesArray?.includes(data.tenantName)) {
                if (data) {
                    setTenantContactDetails!(prev => prev ? [...prev, data] : [data])
                    setEmail('')
                    setEmailError('')
                    setName('')
                    setNameError('')
                    setDesignation('')
                    setDesignationError('')
                    setContactNo('')
                    setContactNoError('')
                }
            } else setNameError('Name already exists')
            // setToListError('')
            // }
        }
    }

    return (<div className='container'>
        <h4>Tenant Contact Information</h4>
        <hr />
        <div className="row">
            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    name='Name'
                    label='Name'
                    labelClassName="required"
                    value={name}
                    onChange={handleName}
                    placeholder=' Name'
                    errorText={nameError}
                    containerClass="mb-2"
                />
            </div>

            {/* <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    name='Last Name'
                    label='Last Name'
                    labelClassName="required"
                    value={lastName}
                    onChange={handleLastName}
                    placeholder='Last Name'
                    errorText={lastNameError}
                    containerClass="mb-2"
                />
            </div> */}
            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    name='Designation'
                    label='Designation'
                    labelClassName="required"
                    value={designation}
                    onChange={handleDesignation}
                    placeholder=' Designation'
                    errorText={designationError}
                    containerClass="mb-2"
                />
            </div>

            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    name='Email'
                    label='Email'
                    labelClassName="required"
                    value={email}
                    onChange={handleEmail}
                    placeholder=' Email'
                    errorText={emailError}
                    containerClass="mb-2"
                />
            </div>

            <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    name='Contact No'
                    label='Contact No'
                    labelClassName="required"
                    value={contactNo}
                    onChange={handleContactNo}
                    placeholder='Contact No'
                    errorText={contactNoError}
                    containerClass="mb-2"
                    type="text"
                />
            </div>
            <div className="text-end">
                <button className="btn btn-sm btn-primary fs-6" onClick={handleSave} >Add</button>
            </div>
        </div>
        {tenantContactDetails && tenantContactDetails?.length > 0 && <div className="mt-1">
            <table className="table colored">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Designation</th>
                        <th>Email</th>
                        <th>Contact No</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {tenantContactDetails?.map((tc, i) => {
                        return <tr>
                            <td>{tc.tenantName}</td>
                            <td>{tc.tenantDesignation}</td>
                            <td>{tc.tenantEmail}</td>
                            <td>{tc.tenantContactNo}</td>
                            <td> <button className="btn btn-sm btn-primary fs-6" onClick={() => handleRemoveItem(tc)}>-</button></td>
                        </tr>
                    })
                    }
                </tbody>
            </table>

        </div>
        }
    </div >)
}
export default ContactForm