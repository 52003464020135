import { nanoid } from "@reduxjs/toolkit";
import {
  ChangeEventHandler,
  useEffect,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../../components/Modal";
import CountryForm from "../../components/admin/CountryForm";
// import AdminTable from "../../components/custom/components/AdminTable";
import {
  Tbody,
  Thead,
} from "../../components/custom/components/TradeCategoryPerformanceTable";
import AdminLayout from "../../components/custom/layouts/AdminLayout";
import { ToastTypes, useToast } from "../../components/toast/ToastProvider";
import { FormType, FormTypeList } from "../../config";
import {
  CountryType,
  deleteCountry,
  fetchCountry,
} from "../../redux/actions/countryActions";
import { AppDispatch, StoreState } from "../../redux/store";
import { useLocation, useSearchParams } from "react-router-dom";
import AdminTable2 from "../../components/custom/components/AdminTable2";
import { AuthAdminType } from "../../redux/actions/authAdminActions";
import { AdminLog } from "../../config/common";




//interface TableBody {
 // sno: number;
 // countrycode: string;
 // countryname: string;
 // currencyname: string;
 // shortcode: string;
 // noofdecimal: number;
 // decimalshortname: string;
 // currencysymbol: string;
 // divisionvalue: string;
 // onEdit: MouseEventHandler<HTMLButtonElement>;
 // onDelete: MouseEventHandler<HTMLButtonElement>;
 // onView: MouseEventHandler<HTMLButtonElement>;
//}

// type HandleEdit = (value: CountryType) => void;
// type HandleDelete = (value: CountryType) => void;
// type HandleView = (value: CountryType) => void;

const Country = () => {
  const toast = useToast();
  const dispatch = useDispatch<AppDispatch>();
  const CountryList = useSelector<StoreState, Array<CountryType>>(
    (state) => state.country
  );
  const pageName = useLocation()
  const authAdmin = useSelector<StoreState, AuthAdminType | null>(
    (state) => state.authAdmin
  );
  const adminAuthendicated =
  typeof authAdmin?.["auth-token"] === "string" &&
  authAdmin?.["auth-token"] !== undefined &&
  authAdmin?.["auth-token"] !== "";
  const [showForm, setShowForm] = useState<boolean>(false);
  const [formType, setFormType] = useState<FormType>(FormTypeList.ADD);
  const [editData, setEditData] = useState<CountryType>();
  const [showViewForm, setShowViewForm] = useState<boolean>(false);
  const [id, setId] = useState<number>();
  const [searchQuery, setSearchQuery] = useSearchParams();
  const searchTerm = searchQuery.get("search") || "";

  const searchCompanyHandler = (
    holdingCompany: CountryType,
    searchTerm: string
  ) => {
    return searchTerm !== ""
      ? new RegExp(`^${searchTerm}|${searchTerm}`, "gi").test(
          holdingCompany.countryname
        )
      : true || [];
  };

  const searchHandler: ChangeEventHandler<HTMLInputElement> = (e) => {
    setSearchQuery({ search: e.target.value });
  };

  const [countryData, setCountryData] = useState<CountryType[]>([]);

  const limit = 20;
  const [offset, setOffset] = useState<number>(0);
  const [range, setRange] = useState<number[]>([]);
  const [length, setLength] = useState<number>(0);

  useEffect(() => {
    if (CountryList?.length > 0) {
      setLength(CountryList?.length ? CountryList?.length : 0);
    }
  }, [CountryList]);

  const handleApply = async (offsetVal = offset) => {
    if (CountryList?.length > 0) {
      const getData = {
        limit: limit?.toString(),
        offset: offsetVal?.toString(),
      };

      if (
        getData !== undefined &&
        getData.limit !== "" &&
        getData.offset !== "" &&
        getData.offset !== undefined &&
        getData.offset !== "NaN"
      ) {
        const filteredCountryList: CountryType[] = CountryList?.slice(
          offset,
          limit + offset
        );
        if (filteredCountryList?.length > 0) {
          setCountryData([...filteredCountryList]);
        } else {
          setCountryData([]);
        }
      }
    }
  };

  useEffect(() => {
    handleApply();
  }, [CountryList]);

  const getTableData = () => {
    var tableHead: Thead[] = [
      { id: "S.No", children: "S.No", text: "S.No" },
      { id: "Country Code", children: "Country Code", text: "Country Code" },
      { id: "Country Name", children: "Country Name", text: "Country Name" },
      { id: "Currency Name", children: "Currency Name", text: "Currency Name" },
      { id: "Short Code", children: "Short Code", text: "Short Code" },
      {
        id: "Number Of Decimal",
        children: "Number Of Decimal",
        text: "Number Of Decimal",
      },
      {
        id: "Decimal Short Name",
        children: "Decimal Short Name",
        text: "Decimal Short Name",
      },
      {
        id: "Currency Symbol",
        children: "Currency Symbol",
        text: "Currency Symbol",
      },
      {
        id: "Division Value",
        children: "Division Value",
        text: "Division Value",
      },
      { id: "Action", children: "Action", text: "Action" },
    ];
    var tableBody: Tbody[][] = [];

    countryData
      .filter((cr) => searchCompanyHandler(cr, searchTerm))
      .forEach((item, i) => {
        var tBody: Tbody[] = [];
        const pageNumber = Math.floor(offset / limit) + 1;
        tBody.push(
          {
            id: nanoid(),
            theadId: "S.No",
            children: limit * pageNumber + i - limit + 1,
            text: (limit * pageNumber + i - limit + 1)?.toString(),
          },
          {
            id: nanoid(),
            theadId: "Country Code",
            children: item.countrycode,
            text: item.countrycode,
          },
          {
            id: nanoid(),
            theadId: "Country Name",
            children: item.countryname,
            text: item.countryname,
          },
          {
            id: nanoid(),
            theadId: "Currency Name",
            children: item.currencyname,
            text: item.currencyname?.toString(),
          },
          {
            id: nanoid(),
            theadId: "Short Code",
            children: item.shortcode,
            text: item.shortcode,
          },
          {
            id: nanoid(),
            theadId: "Number Of Decimal",
            children: item.noofdecimal,
            text: item.noofdecimal?.toString(),
          },
          {
            id: nanoid(),
            theadId: "Decimal Short Name",
            children: item.decimalshortname,
            text: item.decimalshortname,
          },
          {
            id: nanoid(),
            theadId: "Currency Symbol",
            children: item.currencysymbol,
            text: item.currencysymbol?.toString(),
          },
          {
            id: nanoid(),
            theadId: "Division Value",
            children: item.divisionvalue,
            text: item.divisionvalue?.toString(),
          },
          {
            id: nanoid(),
            theadId: "Action",
            children: "action",
            className: "d-none",
            text: item.id?.toString(),
          }
        );
        tableBody.push(tBody);
        // })
      });

    return { tbody: tableBody, thead: tableHead };
  };
  const handleAdd = () => {
    setShowForm(true);
    if(adminAuthendicated){
      const logProps1 = {
        action: 'Create',
        event: `${pageName.pathname.replace("\\", " ")} page | Create Country Clicked`
      };
      AdminLog(logProps1)
    }
  };

  const handleView = () => {
    setFormType(FormTypeList.VIEW);
    if (id !== undefined) {
      var obj = CountryList.find((c) => c.id === id);
      setShowForm(true);
      setEditData(obj);
      setShowViewForm(true);
      if(adminAuthendicated){
        const logProps1 = {
          action: 'View',
          event: `${pageName.pathname.replace("\\", " ")} page |  ${obj?.countryname} Country Viewed | View Clicked`
        };
        AdminLog(logProps1)
      }
    }
  };

  const handleEdit = () => {
    setFormType(FormTypeList.EDIT);
    if (id !== undefined) {
      var obj = CountryList.find((c) => c.id === id);
      setEditData(obj);
      setShowForm(true);
      if(adminAuthendicated){
        const logProps1 = {
          action: 'Edit',
          event: `${pageName.pathname.replace("\\", " ")} page | Country - ${obj?.countryname} Country | Edit Clicked`
        };
        AdminLog(logProps1)
      }
    }
  };

  const handleDelete = () => {
    setFormType(FormTypeList.DELETE);
    if (id !== undefined) {
      dispatch(deleteCountry(id))
        .then(async (text) => {
          dispatch(fetchCountry());
          toast(text, ToastTypes.SUCCESS);
          var obj = CountryList.find((c) => c.id === id);
          if(adminAuthendicated){
            const logProps1 = {
              action: 'Delete',
              event: `${pageName.pathname.replace("\\", " ")} page | Country - ${obj?.countryname} | Delete Clicked`
            };
            AdminLog(logProps1)
          }
          if (countryData !== undefined && countryData?.length === 1) {
            let newOffset = offset - limit < 0 ? 0 : offset - limit;
            setOffset(newOffset);
            await handleApply(newOffset);
          } else {
            await handleApply(); //issue starts from here
          }
          handleFormClose();
        })
        .catch((text) => {});
    }
  };

  const handleFormClose = () => {
    setShowForm(false);
    setFormType(FormTypeList.ADD);
    setEditData(undefined);
    setId(undefined);
    setShowViewForm(false);
  };

  useEffect(() => {
    if (id !== undefined && formType === FormTypeList.VIEW) handleView();
    else if (id !== undefined && formType === FormTypeList.EDIT) handleEdit();
    else if (id !== undefined && formType === FormTypeList.DELETE)
      handleDelete();
  }, [id, formType]);

  useEffect(() => {
    var range = [];
    var num = Math.ceil(length / limit);
    // let i = 1;

    for (let i = 1; i <= num; i++) {
      range.push(i);
    }

    setRange(range);
  }, [length, limit]);

  useEffect(()=>{
    if(adminAuthendicated){
    const logProps1 = {
      action:`Visit`,
      event: `${pageName.pathname.replace("\\", " ")} page | Visited `
    };
    AdminLog(logProps1)
  }
  },[])

  return (
    <>
      <Modal
        headerText={formType + " COUNTRY"}
        visible={showForm}
        onClose={handleFormClose}
        size="xl"
        centered
      >
        <CountryForm
          formType={formType}
          editData={editData}
          onSave={handleFormClose}
          showViewForm={showViewForm}
        />
      </Modal>
      <AdminLayout>
        {/* <PageTitle
                title='Country Management'
                buttonText='Create New Country'
                onButtonClick={handleAdd}
            /> */}
        <AdminTable2
          addBtnText="Create New Country"
          onAdd={handleAdd}
          onEdit={handleEdit}
          onDelete={handleDelete}
          onView={handleView}
          search={searchHandler}
          limit={limit}
          setOffset={setOffset}
          offset={offset}
          onPageNoChange={handleApply}
          range={range}
          pageTitle="Country Management"
          path="/admin/country_management"
          // data={data}
          setId={setId}
          {...getTableData()}
        />
        {/* <div className="card mt-3">
                <div className="card-body">
                    <div className='row'>
                        <div className="col-lg-6 col-sm-12">
                            <h5 className="header-title mb-3 float-start"><i className="fe-align-justify me-1 text-primary"></i>Country Management</h5>
                        </div>
                        <div className="col-lg-6 col-sm-12 text-end mb-1">
                            <button type="button" onClick={handleAdd} className={`me-1 btn btn-xs btn-secondary`}>Create New Country</button>
                        </div>
                    </div>
                    <hr className='hr mt-0' />
                    <div className='table-wrapper'>
                        <table className='table colored'>
                            <thead>
                                <tr>
                                    <th className='text-truncate align-middle'>S.No</th>
                                    <th className='text-truncate align-middle text-center'>Country Code</th>
                                    <th className='text-truncate align-middle text-center'>Country Name</th>
                                    <th className='text-truncate align-middle text-center'>Currency Name</th>
                                    <th className='text-truncate align-middle text-center'>Short Code</th>
                                    <th className='text-truncate align-middle text-center'>Number Of Decimal</th>
                                    <th className='text-truncate align-middle text-center'>Decimal Short Name</th>
                                    <th className='text-truncate align-middle text-center'>Currency Symbol</th>
                                    <th className='text-truncate align-middle text-center'>Division Value</th>
                                    <th className='text-truncate align-middle text-center'>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {CountryList.map((u, i) => {
                                    return <CountryBody
                                        sno={i + 1}
                                        countrycode={u.countrycode}
                                        countryname={u.countryname}
                                        currencyname={u.currencyname}
                                        shortcode={u.shortcode}
                                        noofdecimal={u.noofdecimal}
                                        decimalshortname={u.decimalshortname}
                                        currencysymbol={u.currencysymbol}
                                        divisionvalue={u.divisionvalue}
                                        key={i}
                                        onEdit={() => handleEdit(u)}
                                        onDelete={() => handleDelete(u)}
                                        onView={() => handleView(u)}
                                    />
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div> */}
      </AdminLayout>
    </>
  );
};

export default Country;
