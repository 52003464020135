import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { FormType, FormTypeList } from "../../config";
import api, { APIResponse, getAxiosRequestConfig } from "../../config/api";
import {
  ITDetailsDropDown,
  ITDetailsTypes,
} from "../../redux/actions/ITDetailsActions";
import {
  PaxAreaTypes,
  PaxTypeDropDown,
} from "../../redux/actions/leaseInfoActions";
import {
  TenantManagerType,
  addTenantManager,
  editTenantManager,
} from "../../redux/actions/tenantManagerActions";
import { AppDispatch, StoreState } from "../../redux/store";
import { ToastTypes, useToast } from "../toast/ToastProvider";
import { AuthAdminType } from "../../redux/actions/authAdminActions";
import { AdminLog } from "../../config/common";

export interface TenantContactDetails {
  tenantName: string;
  tenantDesignation: string;
  tenantEmail: string;
  tenantContactNo: string;
}

export interface BrandContactDetails {
  brandName: string;
  brandDesignation: string;
  brandEmail: string;
  brandContactNo: string;
}

interface editType {
  TenantManager: [
    {
      tenantid: number;
      displayid: string;
      brandid: number;
      brandname: string;
      brandcompanyid: number;
      companyname: string;
      tenantcatid: number;
      floorid: number;
      buildingid: number;
      centerid: number;
      tradecatid: number;
      tradesubcatid: number;
      status: string;
      active: true;
      livefrom: string;
      lounge: boolean;
      franchise: boolean;
      tradecatname: null;
      tradesubcatname: null;
      forex: boolean;
      posterminals: number;
      capturemode: string;
      datapullby: string;
      dIservicename: string;
      version: string;
      frequenc: string;
      revenuedata: true;
      releasedon: string;
      deactivationdate: string;
      reason: string;
      datatransmode: string;
      postype: string;
      posname: string;
      ITteam: string;
    }
  ];
  TenantManager2: [
    {
      tenantid: number;
      displayid: string;
      brandid: number;
      companyid: number;
      tenantcatid: number;
      floorid: number;
      landmark: string;
      buildingid: number;
      centerid: number;
      livefrom: string;
      lounge: boolean;
      tradecatid: number;
      tradesubcatid: number;
      site_displayid: null;
      carpetarea: string;
      chargeablearea: string;
      unitname: string | null;
      paxtype: string | null;
      isfranchise: boolean;
      createdby: string | null;
      updatedby: string | null;
      leaseid: string;
    }
  ];
  TenantManagerContact: [
    {
      id: number;
      tenantid: number;
      name: string;
      designation: string;
      email: string;
      mobileNumber: string;
      type: string;
    }
  ];
}

export type TenantManagerContextType = {
  handleSave: () => void;
  resetStates: () => void;
  generalInfoNextErrorHandler: () => boolean;
  leaseInfoNextErrorHandler: () => boolean;
  itDetailsNextErrorHandler: () => boolean;
  formType: FormType;
  id: number | undefined;
  setId: React.Dispatch<React.SetStateAction<number | undefined>>;
  setFormType: React.Dispatch<React.SetStateAction<FormType>>;
  showForm: boolean;
  setShowForm: React.Dispatch<React.SetStateAction<boolean>>;
  portfolioId?: string;
  centerId?: string;
  setportfolioId?: React.Dispatch<React.SetStateAction<string>>;
  setCenterId?: React.Dispatch<React.SetStateAction<string>>;
  portfolioIdError?: string;
  setportfolioIdError?: React.Dispatch<React.SetStateAction<string>>;
  centerIdError?: string;
  setCenterIdError?: React.Dispatch<React.SetStateAction<string>>;
  //general form
  franchise?: boolean;
  company?: string;
  brand?: string;
  tradeCategory?: string;
  tradeSubCategory?: string;
  tenantCategory?: string;
  tenantManagerName?: string;
  franchiseError?: string;
  tradeCategoryError?: string;
  tradeSubCategoryError?: string;
  tenantCategoryError?: string;
  brandError?: string;
  tenantManagerNameError?: string;
  companyName?: string;
  tradeCategoryName?: string;
  tradeSubCategoryName?: string;
  tenantCategoryName?: string;
  brandName?: string;
  setCompanyName?: React.Dispatch<React.SetStateAction<string>>;
  setTradeCategoryName?: React.Dispatch<React.SetStateAction<string>>;
  setTradeSubCategoryName?: React.Dispatch<React.SetStateAction<string>>;
  setTenantCategoryName?: React.Dispatch<React.SetStateAction<string>>;
  setBrandName?: React.Dispatch<React.SetStateAction<string>>;
  setCompany?: React.Dispatch<React.SetStateAction<string>>;
  setFranchise?: React.Dispatch<React.SetStateAction<boolean>>;
  setTradeCategory?: React.Dispatch<React.SetStateAction<string>>;
  setTradeSubCategory?: React.Dispatch<React.SetStateAction<string>>;
  setTenantCategory?: React.Dispatch<React.SetStateAction<string>>;
  setBrand?: React.Dispatch<React.SetStateAction<string>>;
  setTenantManagerName?: React.Dispatch<React.SetStateAction<string>>;
  setFranchiseError?: React.Dispatch<React.SetStateAction<string>>;
  setTradeCategoryError?: React.Dispatch<React.SetStateAction<string>>;
  setTradeSubCategoryError?: React.Dispatch<React.SetStateAction<string>>;
  setTenantCategoryError?: React.Dispatch<React.SetStateAction<string>>;
  setBrandError?: React.Dispatch<React.SetStateAction<string>>;
  setTenantManagerNameError?: React.Dispatch<React.SetStateAction<string>>;
  //lease form
  leaseid?: string;
  setLeaseId?: React.Dispatch<React.SetStateAction<string>>;
  carpetArea?: string;
  setCarpetArea?: React.Dispatch<React.SetStateAction<string>>;
  chargeableArea?: string;
  setChargeableArea?: React.Dispatch<React.SetStateAction<string>>;
  landmark?: string;
  setLandmark?: React.Dispatch<React.SetStateAction<string>>;
  buildingTerminal?: string;
  setBuildingTerminal?: React.Dispatch<React.SetStateAction<string>>;
  location?: string;
  setLocation?: React.Dispatch<React.SetStateAction<string>>;
  paxType?: string;
  setPaxType?: React.Dispatch<React.SetStateAction<PaxAreaTypes>>;
  unitName?: string;
  setUnitName?: React.Dispatch<React.SetStateAction<string>>;
  leaseIdError?: string;
  setLeaseIdError?: React.Dispatch<React.SetStateAction<string>>;
  carpetAreaError?: string;
  setCarpetAreaError?: React.Dispatch<React.SetStateAction<string>>;
  leasableAreaError?: string;
  setLeasableAreaError?: React.Dispatch<React.SetStateAction<string>>;
  landmarkError?: string;
  setLandmarkError?: React.Dispatch<React.SetStateAction<string>>;
  buildingTerminalError?: string;
  setBuildingTerminalError?: React.Dispatch<React.SetStateAction<string>>;
  locationError?: string;
  setLocationError?: React.Dispatch<React.SetStateAction<string>>;
  paxTypeError?: string;
  setPaxTypeError?: React.Dispatch<React.SetStateAction<string>>;
  unitNameError?: string;
  setUnitNameError?: React.Dispatch<React.SetStateAction<string>>;
  //IT form
  posType?: ITDetailsTypes;
  posName?: string;
  ITTeam?: ITDetailsTypes;
  setPosType?: React.Dispatch<React.SetStateAction<ITDetailsTypes>>;
  setPosName?: React.Dispatch<React.SetStateAction<string>>;
  setITTeam?: React.Dispatch<React.SetStateAction<ITDetailsTypes>>;
  posTypeError?: string;
  setPosTypeError?: React.Dispatch<React.SetStateAction<string>>;
  posNameError?: string;
  setPosNameError?: React.Dispatch<React.SetStateAction<string>>;
  ITTeamError?: string;
  setITTeamError?: React.Dispatch<React.SetStateAction<string>>;
  //tenant contact form
  tenantContactDetails?: TenantContactDetails[];
  setTenantContactDetails?: React.Dispatch<
    React.SetStateAction<TenantContactDetails[]>
  >;
  //brand contact form
  brandContactDetails?: BrandContactDetails[];
  setBrandContactDetails?: React.Dispatch<
    React.SetStateAction<BrandContactDetails[]>
  >;
} | null;

interface TenantManagerProviderProps {
  children?: React.ReactNode;
}

export const TenantManagerContext =
  React.createContext<TenantManagerContextType>(null);

export const TenantManagerProvider = (props: TenantManagerProviderProps) => {
  const toast = useToast();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const pageName = useLocation()
  const authUserToken = useSelector<StoreState, AuthAdminType | null>(
    (state) => state.authAdmin
  )?.["auth-token"]!;
  const config = getAxiosRequestConfig(authUserToken);

  const [showForm, setShowForm] = useState<boolean>(false);
  const [editData, setEditData] = useState<any>();
  const [id, setId] = useState<number>();
  const [formType, setFormType] = useState<FormType>(FormTypeList.ADD);
  const [portfolioId, setportfolioId] = useState<string>("");
  const [portfolioIdError, setportfolioIdError] = useState<string>("");
  const [centerId, setCenterId] = useState<string>("");
  const [centerIdError, setCenterIdError] = useState<string>("");

  //general
  const [franchise, setFranchise] = useState<boolean>(false);
  const [company, setCompany] = useState<string>("");
  const [companyName, setCompanyName] = useState<string>("");
  const [tradeCategory, setTradeCategory] = useState<string>("");
  const [tradeCategoryName, setTradeCategoryName] = useState<string>("");
  const [tradeSubCategory, setTradeSubCategory] = useState<string>("");
  const [tradeSubCategoryName, setTradeSubCategoryName] = useState<string>("");
  const [tenantCategory, setTenantCategory] = useState<string>("");
  const [tenantCategoryName, setTenantCategoryName] = useState<string>("");
  const [brand, setBrand] = useState<string>("");
  const [brandName, setBrandName] = useState<string>("");
  const [tenantManagerName, setTenantManagerName] = useState<string>("");
  const [franchiseError, setFranchiseError] = useState<string>("");
  const [tradeCategoryError, setTradeCategoryError] = useState<string>("");
  const [tradeSubCategoryError, setTradeSubCategoryError] =
    useState<string>("");
  const [tenantCategoryError, setTenantCategoryError] = useState<string>("");
  const [brandError, setBrandError] = useState<string>("");
  const [tenantManagerNameError, setTenantManagerNameError] =
    useState<string>("");
  //lease
  const [leaseid, setLeaseId] = useState<string>("");
  const [leaseIdError, setLeaseIdError] = useState<string>("");
  const [carpetArea, setCarpetArea] = useState<string>("");
  const [carpetAreaError, setCarpetAreaError] = useState<string>("");
  const [chargeableArea, setChargeableArea] = useState<string>("");
  const [leasableAreaError, setLeasableAreaError] = useState<string>("");
  const [landmark, setLandmark] = useState<string>("");
  const [landmarkError, setLandmarkError] = useState<string>("");
  const [buildingTerminal, setBuildingTerminal] = useState<string>("");
  const [buildingTerminalError, setBuildingTerminalError] =
    useState<string>("");
  const [location, setLocation] = useState<string>("");
  const [locationError, setLocationError] = useState<string>("");
  const [paxType, setPaxType] = useState<PaxAreaTypes>(PaxTypeDropDown.Arrival);
  const [paxTypeError, setPaxTypeError] = useState<string>("");
  const [unitName, setUnitName] = useState<string>("");
  const [unitNameError, setUnitNameError] = useState<string>("");

  //IT
  const [posType, setPosType] = useState<ITDetailsTypes>(
    ITDetailsDropDown.In_House
  );
  const [posTypeError, setPosTypeError] = useState<string>("");
  const [posName, setPosName] = useState<string>("");
  const [posNameError, setPosNameError] = useState<string>("");
  const [ITTeam, setITTeam] = useState<ITDetailsTypes>(
    ITDetailsDropDown.In_House
  );
  const [ITTeamError, setITTeamError] = useState<string>("");
  //contact
  const [brandContactDetails, setBrandContactDetails] = useState<
    BrandContactDetails[]
  >([]);
  const [tenantContactDetails, setTenantContactDetails] = useState<
    TenantContactDetails[]
  >([]);

  const resetStates = () => {
    // setEditData()
    setFormType(FormTypeList.ADD);
    // setportfolioId('')
    // setportfolioIdError('')
    setCenterId("");
    setCenterIdError("");
    setFranchise(false);
    setCompany("");
    setCompanyName("");
    setTradeCategory("");
    setTradeCategoryName("");
    setTradeSubCategory("");
    setTradeSubCategoryName("");
    setTenantCategory("");
    setTenantCategoryName("");
    setBrand("");
    setBrandName("");
    setTenantManagerName("");
    setFranchiseError("");
    setTradeCategoryError("");
    setTradeSubCategoryError("");
    setTenantCategoryError("");
    setBrandError("");
    setTenantManagerNameError("");
    setLeaseId("");
    setLeaseIdError("");
    setCarpetArea("");
    setCarpetAreaError("");
    setChargeableArea("");
    setLeasableAreaError("");
    setLandmark("");
    setLandmarkError("");
    setBuildingTerminal("");
    setBuildingTerminalError("");
    setLocation("");
    setLocationError("");
    setPaxType(PaxTypeDropDown.Arrival);
    setPaxTypeError("");
    setUnitName("");
    setUnitNameError("");
    setPosType(ITDetailsDropDown.In_House);
    setPosTypeError("");
    setPosName("");
    setPosNameError("");
    setITTeam(ITDetailsDropDown.In_House);
    setITTeamError("");
    setBrandContactDetails([]);
    setTenantContactDetails([]);
  };

  const handleFormClose = () => {
    resetStates();
    setShowForm(false);
    setFormType(FormTypeList.ADD);
    setEditData(undefined);
    setId(undefined);
  };

  const generalInfoNextErrorHandler = () => {
    if (tenantManagerName === "") {
      setTenantManagerNameError("Tenant Name required");
      return true;
    } else if (tenantManagerName && tenantManagerName?.length > 15) {
      setTenantManagerNameError("Tenant Name should be within 15 letters");
      return true;
    } else if (tradeCategory === "") {
      toast("Trade Category required", ToastTypes.ERROR);
      return true;
    } else if (tradeSubCategory === "") {
      toast("Trade Sub-Category required", ToastTypes.ERROR);
      return true;
    } else if (brand === "") {
      toast("Brand required", ToastTypes.ERROR);
      return true;
    } else if (company === "") {
      toast("Company required", ToastTypes.ERROR);
      return true;
    } else return false;
  };

  const leaseInfoNextErrorHandler = () => {
    if (leaseid === "") {
      toast("Lease Id required", ToastTypes.ERROR);
      return true;
    } else if (carpetArea === "") {
      toast("Carpet Area required", ToastTypes.ERROR);
      return true;
    } else if (chargeableArea === "") {
      toast("Chargeable Area required", ToastTypes.ERROR);
      return true;
    } else return false;
  };

  const itDetailsNextErrorHandler = () => {
    if (posName === "") {
      toast("Pos Name required", ToastTypes.ERROR);
      return true;
    } else return false;
  };

  const handleSave = () => {
    let error = false;
    const data: TenantManagerType = {
      portfolioid: portfolioId,
      centerid: centerId,
      tenantManagerName: tenantManagerName,
      franchise: franchise,
      company: company,
      companyName: companyName,
      brand: brand,
      brandName: brandName,
      tradeCategory: tradeCategory,
      tradeCategoryName: tradeCategoryName,
      tradeSubCategory: tradeSubCategory,
      tradeSubCategoryName: tradeSubCategoryName,
      tenantCategory: tenantCategory,
      tenantCategoryName: tenantCategoryName,
      //lease form
      leaseid: leaseid,
      carpetArea: carpetArea,
      chargeableArea: chargeableArea,
      landmark: landmark,
      buildingTerminal: buildingTerminal, //buildingId
      location: location, //floorid
      paxType: paxType,
      unitName: unitName,
      //IT form
      posType: posType,
      posName: posName,
      ITTeam: ITTeam,
      //tenant contact form
      tenantContactDetails: tenantContactDetails,
      //brand contact form
      brandContactDetails: brandContactDetails,
    };
    if (data.portfolioid === "") {
      toast("portfolio required", ToastTypes.ERROR);
      error = true;
    }
    if (data.brand === "") {
      toast("Brand required", ToastTypes.ERROR);
      error = true;
    }
    if (data.company === "") {
      toast("Company required", ToastTypes.ERROR);
      error = true;
    }
    if (data.tradeCategory === "") {
      toast("TradeCategory required", ToastTypes.ERROR);
      error = true;
    }
    if (data.tradeSubCategory === "") {
      toast("TradeSubCategory required", ToastTypes.ERROR);
      error = true;
    }
    if (data.posName === "") {
      toast("Pos Name required", ToastTypes.ERROR);
      error = true;
    }
    if (data.leaseid === "") {
      toast("Lease Id required", ToastTypes.ERROR);
      error = true;
    }
    if (data.carpetArea === "") {
      toast("Carpet Area required", ToastTypes.ERROR);
      error = true;
    }
    if (data.chargeableArea === "") {
      toast("Chargeable Area required", ToastTypes.ERROR);
      error = true;
    }
    if (data.tenantContactDetails && data.tenantContactDetails?.length <= 0) {
      toast("Tenant Contact Details required", ToastTypes.ERROR);
      error = true;
    }
    if (data.brandContactDetails && data.brandContactDetails?.length <= 0) {
      toast("Brand Contact Details required", ToastTypes.ERROR);
      error = true;
    }

    if (data.tenantManagerName === "") {
      setTenantManagerNameError("Tenant Name required");
      error = true;
    } else if (
      data?.tenantManagerName &&
      (data?.tenantManagerName)?.length > 15
    ) {
      setTenantManagerNameError("Tenant Name should be within 15 letters");
      error = true;
    }

    if (!error) {
      if (formType === FormTypeList.ADD) {
        dispatch(addTenantManager(data))
          .then((text) => {
            toast(text, ToastTypes.SUCCESS);
            navigate("/admin/tenant_manager");
            const logProps1 = {
              action: 'Add',
              event: `${pageName.pathname.replace("\\", " ")} page | Save Clicked | Status: Success`
            };
            AdminLog(logProps1)
            handleFormClose();

            // onSave()
          })
          .catch((text) => {
            toast(text, ToastTypes.ERROR);
            const logProps1 = {
              action: 'Add',
              event: `${pageName.pathname.replace("\\", " ")} page | Save Clicked | Status: Failure | Reason : ${text}`
            };
            AdminLog(logProps1)
          });
      } else if (
        formType === FormTypeList.EDIT &&
        editData !== undefined &&
        id !== undefined
      ) {
        dispatch(editTenantManager(data, id))
          .then((text) => {
            toast(text, ToastTypes.SUCCESS);
            navigate("/admin/tenant_manager");
            const logProps1 = {
              action:`Edit`,
              event: `${pageName.pathname.replace("\\", " ")} page | Save Clicked | Status : Success`
            };
            AdminLog(logProps1)
            handleFormClose();
            // onSave()
          })
          .catch((text) => {
            toast(text, ToastTypes.ERROR);
            const logProps1 = {
              action:`Edit`,
              event: `${pageName.pathname.replace("\\", " ")} page | Save Clicked | Status : Failure | Reason : ${text}`
            };
            AdminLog(logProps1)
          });
      }
    }
  };

  useEffect(() => {
    if (formType === FormTypeList.EDIT) {
      if (portfolioId && id) {
        api
          .get<APIResponse<editType>>(
            `tenantManager/${portfolioId}/${id}`,
            config
          )
          .then((response) => {
            if (response.status === 200 && response.data.data) {
              const result = response.data.data;
              setEditData(result);
              const tenant1data = result.TenantManager;
              const tenant2data = result.TenantManager2;
              const tenantcontactData = result.TenantManagerContact;
              const tcontact: TenantContactDetails[] = tenantcontactData
                .filter((t) => t.type === "tenant")
                .map((c) => {
                  return {
                    tenantName: c?.name,
                    tenantDesignation: c.designation,
                    tenantEmail: c.email,
                    tenantContactNo: c.mobileNumber,
                  };
                });
              const bcontact: BrandContactDetails[] = tenantcontactData
                .filter((t) => t.type === "brand")
                .map((c) => {
                  return {
                    brandName: c?.name,
                    brandDesignation: c.designation,
                    brandEmail: c.email,
                    brandContactNo: c.mobileNumber,
                  };
                });
              setBrandContactDetails(bcontact);
              setTenantContactDetails(tcontact);
              setTenantManagerName(tenant1data[0].displayid?.toString());
              setCenterId(tenant1data[0].centerid?.toString());
              setBrand(tenant1data[0].brandid?.toString());
              setBuildingTerminal(tenant1data[0].buildingid?.toString());
              setLocation(tenant1data[0].floorid?.toString());
              setCompany(tenant1data[0].brandcompanyid?.toString());
              setCompanyName(tenant1data[0].companyname?.toString());
              setTradeCategory(tenant1data[0].tradecatid?.toString());
              setTradeCategoryName(
                tenant1data[0].tradecatname ? tenant1data[0].tradecatname : ""
              );
              setTradeSubCategory(tenant1data[0].tradesubcatid?.toString());
              setTradeSubCategoryName(
                tenant1data[0]?.tradesubcatname
                  ? tenant1data[0]?.tradesubcatname
                  : ""
              );
              setTenantCategory(tenant1data[0].tenantcatid?.toString());
              // setTenantCategoryName(tenant1data[0].t?.toString())
              setFranchise(tenant1data[0].franchise);
              setPosName(tenant1data[0].posname?.toString());
              // setPosType(tenant1data[0].brandcompanyid?.toString())
              // setITTeam(tenant1data[0].brandcompanyid?.toString())
              setUnitName(
                tenant2data[0].unitname ? tenant2data[0].unitname : ""
              );
              // setPaxType(tenant2data[0].paxtype ? tenant2data[0].paxtype===PaxTypeDropDown.Arrival? : '')
              setLeaseId(tenant2data[0].leaseid ? tenant2data[0].leaseid : "");
              setCarpetArea(
                tenant2data[0].carpetarea ? tenant2data[0].carpetarea : ""
              );
              setChargeableArea(
                tenant2data[0].chargeablearea
                  ? tenant2data[0].chargeablearea
                  : ""
              );
              setLandmark(
                tenant2data[0].landmark ? tenant2data[0].landmark : ""
              );
            }
          })
          .catch((error) => {});
      } else {
        setEditData([]);
      }
    }
  }, [formType]);

  return (
    <>
      <TenantManagerContext.Provider
        value={{
          portfolioId,
          setportfolioId,
          centerId,
          setCenterId,
          generalInfoNextErrorHandler,
          leaseInfoNextErrorHandler,
          itDetailsNextErrorHandler,
          brand,
          company,
          franchise,
          tenantCategory,
          tenantManagerName,
          tradeCategory,
          tradeSubCategory,
          handleSave,
          resetStates,
          setBrand,
          ITTeam,
          posName,
          brandContactDetails,
          posType,
          setCompany,
          setFranchise,
          setTenantCategory,
          setTenantManagerName,
          setTradeCategory,
          setTradeSubCategory,
          tenantContactDetails,
          setPosType,
          setPosName,
          setITTeam,
          franchiseError,
          tradeCategoryError,
          tradeSubCategoryError,
          tenantCategoryError,
          brandError,
          tenantManagerNameError,
          setFranchiseError,
          setTradeCategoryError,
          setTradeSubCategoryError,
          setTenantCategoryError,
          setBrandError,
          setTenantManagerNameError,
          setCompanyName,
          setTradeCategoryName,
          setTradeSubCategoryName,
          setTenantCategoryName,
          setBrandName,
          leaseid,
          setLeaseId,
          carpetArea,
          setCarpetArea,
          chargeableArea,
          setChargeableArea,
          landmark,
          setLandmark,
          buildingTerminal,
          setBuildingTerminal,
          location,
          setLocation,
          paxType,
          setPaxType,
          unitName,
          setUnitName,
          leaseIdError,
          setLeaseIdError,
          carpetAreaError,
          setCarpetAreaError,
          leasableAreaError,
          setLeasableAreaError,
          landmarkError,
          setLandmarkError,
          buildingTerminalError,
          setBuildingTerminalError,
          locationError,
          setLocationError,
          paxTypeError,
          setPaxTypeError,
          unitNameError,
          setUnitNameError,
          setTenantContactDetails,
          setBrandContactDetails,
          posTypeError,
          setPosTypeError,
          posNameError,
          setPosNameError,
          ITTeamError,
          setITTeamError,
          portfolioIdError,
          setportfolioIdError,
          centerIdError,
          setCenterIdError,
          formType,
          setFormType,
          id,
          setId,
          showForm,
          setShowForm,
        }}
      >
        {props.children}
      </TenantManagerContext.Provider>
    </>
  );
};
