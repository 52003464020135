import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import AdminActivateTenant from "../pages/admin/ActivateTenant";
import AdminTab from "../pages/admin/AddTenantTab";
import AdminAgentAuthentication from "../pages/admin/AgentAuthentication";
import AdminBrandCompanyManagement from "../pages/admin/BrandCompanyManagement";
import AdminBrandManagement from "../pages/admin/BrandManagement";
import AdminBrandCompanyMapping from "../pages/admin/BrandCompanyMapping";
import AdminOutletManagement from "../pages/admin/OutletManagement";
import BuildingManagement from "../pages/admin/BuildingManagement";
import AdminCompanyManagement from "../pages/admin/CompanyManagement";
import AdminContact from "../pages/admin/Contact";
import AdminCountryManagement from "../pages/admin/CountryManagement";
import AdminCredentialManager from "../pages/admin/CredentialManager";
import AdminCultureSetting from "../pages/admin/CultureSetting";
import AdminDeActivateTenant from "../pages/admin/DeActivateTenant";
import FloorManagement from "../pages/admin/FloorManagement";
import AdminHolidaySetting from "../pages/admin/HolidaySetting";
import AdminHome from "../pages/admin/Home";
import AdminLogin from "../pages/admin/Login";
import AdminLogout from "../pages/admin/Logout";
import AdminNotificationAlertManagement from "../pages/admin/NotificationAlertManagement";
import AdminPortfolioManagement from "../pages/admin/PortfolioManagement";
import Role from "../pages/admin/Role";
import AdminSiteManagement from "../pages/admin/SiteManagement";
import AdminTenantCategory from "../pages/admin/TenantCategory";
import AdminTenantManager from "../pages/admin/TenantManager";
import AdminTradeCategoryManagement from "../pages/admin/TradeCategoryManagement";
import AdminTradeSubCategoryManagement from "../pages/admin/TradeSubCategoryManagement";
import AdminUserManagement from "../pages/admin/UserManagement";
import AdminUserLogManagement from "../pages/admin/UserLogManagement";
import { AuthAdminType } from "../redux/actions/authAdminActions";
import { fetchBrand } from "../redux/actions/brandActions";
import { fetchBrandCompany } from "../redux/actions/brandCompanyActions";
import { fetchBrandCompanyMapping } from "../redux/actions/brandCompanyMappingActions";
import { fetchCompany } from "../redux/actions/companyActions";
import {
  fetchCountry,
} from "../redux/actions/countryActions";
import { fetchNotification } from "../redux/actions/notificationActions";
import {
  fetchPortfolio,
} from "../redux/actions/portfolioActions";
import { fetchRole } from "../redux/actions/roleActions";
import {
  OptionsTypes,
  addToSelectedOption,
} from "../redux/actions/selectedOptionsActions";
import { fetchSite } from "../redux/actions/siteActions";
import { fetchTenantCategory } from "../redux/actions/tenantActions";
import { fetchTradeCategory } from "../redux/actions/tradeCategoryActions";
import { fetchTradeSubCategory } from "../redux/actions/tradeSubCategoryActions";
import { fetchUsers } from "../redux/actions/usersActions";
import { AppDispatch, StoreState } from "../redux/store";
import AdminBrandContactForm from "./admin/BrandContactForm";
import AdminContactForm from "./admin/ContactForm";
import AdminGeneralInfoForm from "./admin/GeneralInfoForm";
import AdminITDetailsForm from "./admin/ITDetailsForm";
import AdminLeaseInfoForm from "./admin/LeaseInfoForm";
import { TenantManagerProvider } from "./context/TenantManagerContext";
import Page404 from "../pages/errors/page404";
import {
  setAdminSession,
} from "../redux/actions/commonActions";
import axios from "axios";
import { AdminLog } from "../config/common";

const Router = () => {
  const dispatch = useDispatch<AppDispatch>();
  const authAdmin = useSelector<StoreState, AuthAdminType | null>(
    (state) => state.authAdmin
  );
  const adminAuthendicated =
    typeof authAdmin?.["auth-token"] === "string" &&
    authAdmin?.["auth-token"] !== undefined &&
    authAdmin?.["auth-token"] !== "";

  const selectedOption = useSelector<StoreState, OptionsTypes>(
    (state) => state.selectedOption
  );

  useEffect(() => {
    if (adminAuthendicated) {
      dispatch(fetchPortfolio());
      dispatch(fetchSite());
      dispatch(fetchBrandCompany());
      dispatch(fetchBrandCompanyMapping());
      dispatch(fetchBrand());
      dispatch(fetchCompany());
      dispatch(fetchTenantCategory());
      dispatch(fetchTradeCategory());
      dispatch(fetchTradeSubCategory());
      dispatch(fetchUsers());
      dispatch(fetchRole());
      dispatch(fetchNotification());
      dispatch(fetchCountry());
      const logProps1 = {
        action:`Login`,
        event: `Admin LoggedIn`
      };
      AdminLog(logProps1)
    }
  }, [adminAuthendicated]);

 
    
 

  

  useEffect(() => {
    if (authAdmin !== null) {
      dispatch(setAdminSession());
    }
  }, [authAdmin,dispatch]);

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/login"
          element={
            !adminAuthendicated ? <AdminLogin /> : <Navigate to="/" />
          }
        />
        <Route
          path="/logout"
          element={
            adminAuthendicated ? (
              <AdminLogout />
            ) : (
              <Navigate to="/login" />
            )
          }
        />

        <Route
          path="/"
          element={
            adminAuthendicated ? <AdminHome /> : <Navigate to="/login" />
          }
        />
        <Route
          path="/admin/company_management"
          element={
            adminAuthendicated ? (
              <AdminCompanyManagement />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/admin/porfolio_management"
          element={
            adminAuthendicated ? (
              <AdminPortfolioManagement />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/admin/center_management"
          element={
            adminAuthendicated ? (
              <AdminSiteManagement />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/admin/brand_Company"
          element={
            adminAuthendicated ? (
              <AdminBrandCompanyManagement />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/admin/brand_management"
          element={
            adminAuthendicated ? (
              <AdminBrandManagement />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/admin/brand_mapping"
          element={
            adminAuthendicated ? (
              <AdminBrandCompanyMapping />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/admin/outlet_management"
          element={
            adminAuthendicated ? (
              <AdminOutletManagement />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/admin/trade_category"
          element={
            adminAuthendicated ? (
              <AdminTradeCategoryManagement />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/admin/trade_sub_category"
          element={
            adminAuthendicated ? (
              <AdminTradeSubCategoryManagement />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/admin/user_management"
          element={
            adminAuthendicated ? (
              <AdminUserManagement />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/admin/user-log-management"
          element={
            adminAuthendicated ? (
              <AdminUserLogManagement />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/admin/country_management"
          element={
            adminAuthendicated ? (
              <AdminCountryManagement />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/admin/notification_management"
          element={
            adminAuthendicated ? (
              <AdminNotificationAlertManagement />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/admin/building_management"
          element={
            adminAuthendicated ? (
              <BuildingManagement />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/admin/floor_management"
          element={
            adminAuthendicated ? (
              <FloorManagement />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/admin/tenant_category"
          element={
            adminAuthendicated ? (
              <AdminTenantCategory />
            ) : (
              <Navigate to="/login" />
            )
          }
        />

        <Route
          path="/admin/tenant_manager"
          element={
            adminAuthendicated ? (
              <TenantManagerProvider>
                <AdminTenantManager />
              </TenantManagerProvider>
            ) : (
              <Navigate to="/login" />
            )
          }
        />

        <Route
          path="/admin/credential_manager"
          element={
            adminAuthendicated ? (
              <AdminCredentialManager />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/admin/agent_authentication"
          element={
            adminAuthendicated ? (
              <AdminAgentAuthentication />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/admin/activate_tenant"
          element={
            adminAuthendicated ? (
              <AdminActivateTenant />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/admin/deactivate_tenant"
          element={
            adminAuthendicated ? (
              <AdminDeActivateTenant />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/admin/culture_setting"
          element={
            adminAuthendicated ? (
              <AdminCultureSetting />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/admin/holiday_setting"
          element={
            adminAuthendicated ? (
              <AdminHolidaySetting />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/admin/contact"
          element={
            adminAuthendicated ? (
              <AdminContact />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/admin/Tab"
          element={
            adminAuthendicated ? <AdminTab /> : <Navigate to="/login" />
          }
        />
        <Route
          path="/admin/brand_contact_form"
          element={
            adminAuthendicated ? (
              <AdminBrandContactForm />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/admin/contact_form"
          element={
            adminAuthendicated ? (
              <AdminContactForm />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/admin/general_info_form"
          element={
            adminAuthendicated ? (
              <AdminGeneralInfoForm />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/admin/itdetails_form"
          element={
            adminAuthendicated ? (
              <AdminITDetailsForm />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/admin/lease_info_form"
          element={
            adminAuthendicated ? (
              <AdminLeaseInfoForm />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/admin/role"
          element={
            adminAuthendicated ? <Role /> : <Navigate to="/login" />
          }
        />
        <Route path="*" element={<Page404 />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
