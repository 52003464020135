import { ChangeEventHandler, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormType, FormTypeList } from "../../config";
import { CompanyType } from "../../redux/actions/companyActions";
import { PortfolioType } from "../../redux/actions/portfolioActions";
import {
  ActiveDropDown,
  ActiveTypes,
  SiteType,
  addSite,
  editSite,
  fetchSite,
} from "../../redux/actions/siteActions";
import {
  RegionType,
  StateType,
  CityType,
  fetchRegion,
  fetchState,
  fetchCity,
  clearRegion,
  clearState,
  clearCity,
} from "../../redux/actions/commonActions";
import { AppDispatch, StoreState } from "../../redux/store";
import Option from "../Option";
import { FormInput } from "../custom/components";
import { ToastTypes, useToast } from "../toast/ToastProvider";
import { testNumber, today } from "../../config/functions";
import { CountryType } from "../../redux/actions/countryActions";
import { useLocation } from "react-router-dom";
import { AuthAdminType } from "../../redux/actions/authAdminActions";
import { AdminLog } from "../../config/common";

const ACTIVE_DROPS = [
  {
    text: "Active",
    value: true,
  },
  {
    text: "InActive",
    value: false,
  },
];

interface Props {
  formType: FormType;
  editData?: SiteType;
  onSave: () => void;
  showViewForm: boolean;
}

const SiteForm = ({ formType, onSave, editData, showViewForm }: Props) => {
  const toast = useToast();
  const dispatch = useDispatch<AppDispatch>();
  const pageName = useLocation()
  const authAdmin = useSelector<StoreState, AuthAdminType | null>(
    (state) => state.authAdmin
  );
  const adminAuthendicated =
  typeof authAdmin?.["auth-token"] === "string" &&
  authAdmin?.["auth-token"] !== undefined &&
  authAdmin?.["auth-token"] !== "";
  const portfolioList = useSelector<StoreState, Array<PortfolioType>>(
    (state) => state.portfolio
  );

  const companies = useSelector<StoreState, Array<CompanyType>>(
    (state) => state.company
  );

  const countryList = useSelector<StoreState, Array<CountryType>>(
    (state) => state.country
  );

  const regionList = useSelector<StoreState, Array<RegionType>>(
    (state) => state.region
  );
  const StateList = useSelector<StoreState, Array<StateType>>(
    (state) => state.state
  );
  const CityList = useSelector<StoreState, Array<CityType>>(
    (state) => state.city
  );

  const [name, setName] = useState<string>("");
  const [nameError, setNameError] = useState<string>("");
  const [code, setCode] = useState<string>("");
  const [codeError, setCodeError] = useState<string>("");
  const [url, setUrl] = useState<string>("");
  const [urlError, setUrlError] = useState<string>("");
  const [pfid, setPfid] = useState<string>("");
  const [pfidError, setPfidError] = useState<string>("");
  const [centertype, setCentertype] = useState<string>("");
  const [centertypeError, setCentertypeError] = useState<string>("");
  const [gla, setGla] = useState<string>("");
  const [glaError, setGlaError] = useState<string>("");
  const [uom, setUom] = useState<string>("");
  const [uomError, setUomError] = useState<string>("");
  const [salesby, setSalesby] = useState<string>("");
  const [salesbyError, setSalesbyError] = useState<string>("");
  const [street, setStreet] = useState<string>("");
  const [streetError, setStreetError] = useState<string>("");
  const [countryid, setcountryid] = useState<string>("");
  const [countryidError, setcountryidError] = useState<string>("");
  const [regionid, setregionid] = useState<string>("");
  const [regionidError, setregionidError] = useState<string>("");
  const [stateid, setstateid] = useState<string>("");
  const [stateidError, setstateidError] = useState<string>("");
  const [cityid, setcityid] = useState<string>("");
  const [cityyidError, setcityidError] = useState<string>("");
  const [pincode, setPincode] = useState<string>("");
  const [pincodeError, setPincodeError] = useState<string>("");
  const [logopath, setLogopath] = useState<string>("");
  const [logopathError, setLogopathError] = useState<string>("");
  const [logourl, setLogourl] = useState<string>("");
  const [logourlError, setLogourlError] = useState<string>("");
  const [active, setActive] = useState<ActiveTypes>(ActiveDropDown.Active);
  const [activeError, setActiveError] = useState<string>("");
  const [livefrom, setLivefrom] = useState<string>("");
  const [livefromError, setLivefromError] = useState<string>("");

  // const [portfolioName, setPortfolioName] = useState<string>("");
  // const [companyid, setCompanyid] = useState<string>("");
  // const [companyidError, setCompanyidError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const [pfcurrcode, setpfcurrcode] = useState<string>("");
  const [pfcurrsymbol, setpfcurrsymbol] = useState<string>("");
  const [hccurrcode, sethccurrcode] = useState<string>("");
  const [hccurrsymbol, sethccurrsymbol] = useState<string>("");

  const portfolioFilteredByBM = portfolioList.filter(
    (a) => a.businessmodel?.toString() !== "1"
  );

  const resetStates = () => {
    setName("");
    setNameError("");
    setCode("");
    setCodeError("");
    setUrl("");
    setUrlError("");
    setPfid("");
    setPfidError("");
    setCentertype("");
    setCentertypeError("");
    setGla("");
    setGlaError("");
    setUom("");
    setUomError("");
    setSalesby("");
    setSalesbyError("");
    setStreet("");
    setStreetError("");
    setcountryid("");
    setcountryidError("");
    setregionid("");
    setregionidError("");
    setstateid("");
    setstateidError("");
    setcityid("");
    setcityidError("");
    setPincode("");
    setPincodeError("");
    setLogopath("");
    setLogopathError("");
    setLogourl("");
    setLogourlError("");
    setLivefrom("");
    setLivefromError("");
    setActive(ActiveDropDown.Active);
    setActiveError("");
    setLoading(false);

  };

  const handleName: ChangeEventHandler<HTMLInputElement> = (e) => {
    setName(e.target.value);
    setNameError("");
  };

  const handleCode: ChangeEventHandler<HTMLInputElement> = (e) => {
    setCode(e.target.value);
    setCodeError("");
  };
  const handleUrl: ChangeEventHandler<HTMLInputElement> = (e) => {
    setUrl(e.target.value);
    setUrlError("");
  };

  const handlePfid: ChangeEventHandler<HTMLInputElement> = (e) => {
    setPfid(e.target.value);

    const pindex = portfolioList.findIndex(
      (a) => a.id?.toString() === e.target.value?.toString()
    );

    const hindex = companies.findIndex(
      (a) => a.id?.toString() === portfolioList[pindex].companyid?.toString()
    );


    setPfidError("");
  };

  const handleCentertype: ChangeEventHandler<HTMLInputElement> = (e) => {
    setCentertype(e.target.value);
    setCentertypeError("");
  };

  const handleGla: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (!testNumber(e.target.value) && e.target.value) return;

    setGla(e.target.value);
    setGlaError("");
  };

  const handleUom: ChangeEventHandler<HTMLInputElement> = (e) => {
    setUom(e.target.value);
    setUomError("");
  };
  const handleSalesby: ChangeEventHandler<HTMLInputElement> = (e) => {
    setSalesby(e.target.value);
    setSalesbyError("");
  };
  const handleStreet: ChangeEventHandler<HTMLInputElement> = (e) => {
    setStreet(e.target.value);
    setStreetError("");
  };

  const handleCountryid: ChangeEventHandler<HTMLInputElement> = (e) => {
    setregionid("");
    setstateid("");
    setcityid("");
    setcountryid(e.target.value);
    setcountryidError("");
  };

  const handleRegionid: ChangeEventHandler<HTMLInputElement> = (e) => {
    setstateid("");
    setcityid("");
    setregionid(e.target.value);
    setregionidError("");
  };

  const handleStateid: ChangeEventHandler<HTMLInputElement> = (e) => {
    setcityid("");
    setstateid(e.target.value);
    setstateidError("");
  };

  const handleCityid: ChangeEventHandler<HTMLInputElement> = (e) => {
    setcityid(e.target.value);
    setcityidError("");
  };

  const handlePincode: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (!testNumber(e.target.value) && e.target.value) return;
    else if (e.target.value?.length >= 7) return;

    setPincode(e.target.value);
    setPincodeError("");
  };
  const handleLogopath: ChangeEventHandler<HTMLInputElement> = (e) => {
    setLogopath(e.target.value);
    setLogopathError("");
  };

  const handleLogourl: ChangeEventHandler<HTMLInputElement> = (e) => {
    setLogourl(e.target.value);
    setLogourlError("");
  };

  const handleLivefrom: ChangeEventHandler<HTMLInputElement> = (e) => {
    const { value } = e.target;
    var today = new Date();
    var d1 = today.getDate();
    var m1 = today.getMonth() + 1;
    var y1 = today.getFullYear();

    const liveFromDate = new Date(value);
    var d2 = liveFromDate.getDate();
    var m2 = liveFromDate.getMonth() + 1;
    var y2 = liveFromDate.getFullYear();

    if (m1 === m2 && d1 < d2) return false;
    else if (m1 < m2) return false;
    else if (y1 < y2) return false;
    else {
      setLivefrom(value);
      setLivefromError("");
    }
  };

  const handleActive: ChangeEventHandler<HTMLInputElement> = (e) => {
    setActive(e.target.value as ActiveTypes);
    setActiveError("");
  };

  // const handleCompanyid: ChangeEventHandler<HTMLInputElement> = (e) => {
  //   setCompanyid(e.target.value);
  //   setCompanyidError("");
  // };

  const handleClear = () => {
    resetStates();
  };

  const handleSave = () => {
    let error = false;
    const data: SiteType = {
      name: name,
      code: code,
      url: url,
      pfid: parseInt(pfid),
      centertype: centertype,
      gla: parseInt(gla),
      uom: uom,
      salesby: salesby,
      street: street,
      country: parseInt(countryid),
      region: parseInt(regionid),
      state: parseInt(stateid),
      city: parseInt(cityid),
      pincode: pincode,
      logopath: logopath,
      logourl: logourl,
      active: active,
      livefrom: livefrom,
    };

    if (data.name === "") {
      setNameError("Name required");
      error = true;
    } else if (data.name?.length > 60) {
      setNameError("Name should be within 60 characters");
      error = true;
    }

    if (data.code === "") {
      setCodeError("Code required");
      error = true;
    } else if (data.code?.length > 30) {
      setCodeError("Code should be within 30 characters");
      error = true;
    }

    if (data.url === "") {
      setUrlError("Url required");
      error = true;
    } else if (data.url?.length > 100) {
      setUrlError("Url should be within 100 characters");
      error = true;
    }

    if (isNaN(data.pfid)) {
      setPfidError("Portfolio required");
      error = true;
    }
    /*else if (data.pfid. > 60) {
      setPfidError("Portfolio should be within 60 digits");
      error = true;
    }*/

    if (data.centertype === "") {
      setCentertypeError("Center Type required");
      error = true;
    } else if (data.centertype?.length > 15) {
      setCentertypeError("Center Type should be within 15 letters");
      error = true;
    }

    if (isNaN(data.gla)) {
      setGlaError("Gla required");
      error = true;
    }
    // } else if ((data.gla) > 30) {
    //     setGlaError('Gla should be within 30 digits')
    //     error = true
    // }

    if (data.uom === "") {
      setUomError("Uom required");
      error = true;
    } else if (data.uom?.length > 100) {
      setUomError("Uom should be within 100 characters");
      error = true;
    }

    if (data.salesby === "") {
      setSalesbyError("Salesby required");
      error = true;
    } else if (data.salesby?.length > 100) {
      setSalesbyError("Salesby should be within 100 characters");
      error = true;
    }

    if (isNaN(data.country)) {
      setcountryidError("Country id required");
      error = true;
    }

    // if (isNaN(data.regionid)) {
    //   setregionidError("Region id required");
    //   error = true;
    // }

    if (isNaN(data.city)) {
      setcityidError("City id required");
      error = true;
    }

    if (isNaN(data.state)) {
      setstateidError("State id required");
      error = true;
    }

    if (data.street === "") {
      setStreetError("Street required");
      error = true;
    } else if (data.street?.length > 100) {
      setStreetError("Street should be within 100 characters");
      error = true;
    }

    if (data.pincode === "") {
      setPincodeError("Pincode required");
      error = true;
    } else if (data.pincode && !testNumber(data.pincode)) {
      setPincodeError("Pincode must be a number");
      error = true;
    }

    if (data.logopath === "") {
      setLogopathError("Logopath required");
      error = true;
    } else if (data.logopath?.length > 100) {
      setLogopathError("Logopath should be within 100 characters");
      error = true;
    }

    if (data.logourl === "") {
      setLogourlError("Logourl required");
      error = true;
    } else if (data.logourl?.length > 100) {
      setLogourlError("Logourl should be within 100 characters");
      error = true;
    }

    if (data.active?.toString() === "") {
      setActiveError("Active required");
      error = true;
    }

    if (data.livefrom === "") {
      setLivefromError("Live Form required");
      error = true;
    } else if (data.livefrom?.length > 100) {
      setLivefromError("Live Form should be within 100 characters");
      error = true;
    }

    if (!error) {
      if (formType === FormTypeList.ADD) {
        setLoading(true);
        dispatch(addSite(data))
          .then((text) => {
            toast(text, ToastTypes.SUCCESS);
            const logProps1 = {
              action:`Save`,
              event: `${pageName.pathname.replace("\\", " ")} page | Save Clicked | Status : Success`
            };
            AdminLog(logProps1)
            dispatch(fetchSite());
            onSave();
          })
          .catch((text) => {
            toast(text, ToastTypes.ERROR);
            const logProps1 = {
              action:`Save`,
              event: `${pageName.pathname.replace("\\", " ")} page | Save Clicked | Status : Failure | Reason : ${text}`
            };
            AdminLog(logProps1)
          })
          .finally(() => {
            setLoading(false);
          });
      } else if (
        formType === FormTypeList.EDIT &&
        editData !== undefined &&
        editData.id !== undefined
      ) {
        setLoading(true);
        dispatch(editSite(data, editData.id))
          .then((text) => {
            toast(text, ToastTypes.SUCCESS);
            const logProps1 = {
              action:`Edit`,
              event: `${pageName.pathname.replace("\\", " ")} page | Save Clicked | Status : Success`
            };
            AdminLog(logProps1)
            dispatch(fetchSite());

            onSave();
          })
          .catch((text) => {
            toast(text, ToastTypes.ERROR);
            const logProps1 = {
              action:`Edit`,
              event: `${pageName.pathname.replace("\\", " ")} page | Save Clicked | Status : Failure | Reason : ${text}`
            };
            AdminLog(logProps1)

          })
          .finally(() => {
            setLoading(false);
          });
      }
    }
  };

  useEffect(() => {
    if (countryid) {
      dispatch(fetchRegion(countryid));
      regionid
        ? dispatch(fetchState(countryid, regionid))
        : dispatch(fetchState(countryid, ""));
      stateid ? dispatch(fetchCity(countryid, stateid)) : dispatch(clearCity());
    } else {
      dispatch(clearRegion());
      setregionid("");
      dispatch(clearState());
      setstateid("");
      dispatch(clearCity());
      setcityid("");
    }
  }, [countryid, regionid, stateid]);

  useEffect(() => {
    if (
      (formType === FormTypeList.EDIT || formType === FormTypeList.VIEW) &&
      editData !== undefined
    ) {
      setName(editData.name);
      setCode(editData.code);
      setUrl(editData.url);
      setPfid(editData.pfid?.toString());
      setCentertype(editData.centertype);
      setGla(editData.gla?.toString());
      setUom(editData.uom);
      setSalesby(editData.salesby);
      setcountryid(editData.country?.toString());
      setregionid(editData.region?.toString());
      setstateid(editData.state?.toString());
      setcityid(editData.city?.toString());
      setStreet(editData.street);
      setPincode(editData.pincode);
      setLogopath(editData.logopath);
      setLogourl(editData.logourl);

      setActive(
        editData.active === true
          ? ActiveDropDown.Active
          : ActiveDropDown.InActive
      );
      setLivefrom(editData.livefrom);

      // setPortfolioId(editData.portfolioId !== undefined ? editData.portfolioId?.toString() : '')
    } else {
      resetStates();
    }
  }, [formType, editData]);



  return (
    <div className="container">
      <div className="row">
        <div className="col-sm-6 col-md-4 col-lg-3">
          <FormInput
            name=" Name"
            label=" Name"
            labelClassName="required"
            value={name}
            onChange={handleName}
            placeholder=" Name"
            errorText={nameError}
            containerClass="mb-2"
            disabled={showViewForm}
          />
        </div>
        <div className="col-sm-6 col-md-4 col-lg-3">
          <FormInput
            name=" Code"
            label=" Code"
            labelClassName="required"
            value={code}
            onChange={handleCode}
            placeholder=" Code"
            errorText={codeError}
            containerClass="mb-2"
            disabled={showViewForm}
          />
        </div>
        <div className="col-sm-6 col-md-4 col-lg-3">
          <FormInput
            name="Website Url"
            label="Website Url"
            labelClassName="required"
            value={url}
            onChange={handleUrl}
            placeholder="Website Url"
            errorText={urlError}
            containerClass="mb-2"
            disabled={showViewForm}
          />
        </div>
        {/* <div className='col-sm-6 col-md-4 col-lg-3'>
                <FormInput
                    name='Company Name'
                    label='Company Name'
                    labelClassName="required"
                    value={companyid}
                    onChange={handleCompanyid}
                    placeholder='Company Name'
                    errorText={companyidError}
                    containerClass="mb-2"
                    disabled={showViewForm}
                    type="select"
                >
                    <option value="">Select</option>
                    {companies.map((a, i) => {
                        return <Option value={a.id} key={i}>{a.name}</Option>
                    })}
                </FormInput>
            </div> */}
        <div className="col-sm-6 col-md-4 col-lg-3">
          <FormInput
            name="Portfolio Name"
            label="Portfolio Name"
            labelClassName="required"
            value={pfid}
            onChange={handlePfid}
            placeholder="Portfolio Name"
            errorText={pfidError}
            containerClass="mb-2"
            disabled={showViewForm || (editData && true)}
            type="select"
          >
            <option value="">Select Portfolio</option>
            {portfolioFilteredByBM.map((a, i) => {
              return (
                <Option value={a.id} key={i}>
                  {a?.name}
                </Option>
              );
            })}
          </FormInput>
        </div>
        <div className="col-sm-6 col-md-4 col-lg-3">
          <FormInput
            name="Center Type"
            label="Center Type"
            labelClassName="required"
            value={centertype}
            onChange={handleCentertype}
            placeholder="Center Type"
            errorText={centertypeError}
            containerClass="mb-2"
            disabled={showViewForm}
            type="select"
          >
            <option value="">Select</option>
            {["Retail", "Mall", "Airport"].map((c, i) => (
              <Option value={c} key={i}>
                {c}
              </Option>
            ))}
          </FormInput>
        </div>
        <div className="col-sm-6 col-md-4 col-lg-3">
          <FormInput
            name=" Gla"
            label=" Gla"
            labelClassName="required"
            value={gla}
            onChange={handleGla}
            placeholder=" Gla"
            errorText={glaError}
            containerClass="mb-2"
            disabled={showViewForm}
            type="text"
          />
        </div>
        <div className="col-sm-6 col-md-4 col-lg-3">
          <FormInput
            name=" Uom"
            label=" Uom"
            labelClassName="required"
            value={uom}
            onChange={handleUom}
            placeholder=" Uom"
            errorText={uomError}
            containerClass="mb-2"
            disabled={showViewForm}
          />
        </div>
        <div className="col-sm-6 col-md-4 col-lg-3">
          <FormInput
            name=" Sales By"
            label=" Sales By"
            labelClassName="required"
            value={salesby}
            onChange={handleSalesby}
            placeholder=" Sales By"
            errorText={salesbyError}
            containerClass="mb-2"
            disabled={showViewForm}
            type="select"
          >
            <option value="">Select</option>
            {["Chargeable area", "Carpet area"].map((s, i) => (
              <Option value={s} key={i}>
                {s}
              </Option>
            ))}
          </FormInput>
        </div>

        <div className="col-sm-6 col-md-4 col-lg-3">
          <FormInput
            name="Country Name"
            label="Country Name"
            labelClassName="required"
            value={countryid}
            onChange={handleCountryid}
            placeholder="Country Name"
            errorText={countryidError}
            containerClass="mb-2"
            disabled={showViewForm}
            type="select"
          >
            <option value="">Select Country</option>
            {countryList.map((a, i) => {
              return (
                <Option value={a.id} key={i}>
                  {a.countryname}
                </Option>
              );
            })}
          </FormInput>
        </div>

        <div className="col-sm-6 col-md-4 col-lg-3">
          <FormInput
            name="Region Name"
            label="Region Name"
            labelClassName="required"
            value={regionid}
            onChange={handleRegionid}
            placeholder="Region Name"
            errorText={regionidError}
            containerClass="mb-2"
            disabled={showViewForm}
            type="select"
          >
            <option value="">Select Region</option>
            {regionList.map((a, i) => {
              return (
                <Option value={a.id} key={i}>
                  {a.regionname}
                </Option>
              );
            })}
          </FormInput>
        </div>

        <div className="col-sm-6 col-md-4 col-lg-3">
          <FormInput
            name="State Name"
            label="State Name"
            labelClassName="required"
            value={stateid}
            onChange={handleStateid}
            placeholder="State Name"
            errorText={stateidError}
            containerClass="mb-2"
            disabled={showViewForm}
            type="select"
          >
            <option value="">Select State</option>
            {StateList.map((a, i) => {
              return (
                <Option value={a.id} key={i}>
                  {a.statename}
                </Option>
              );
            })}
          </FormInput>
        </div>

        <div className="col-sm-6 col-md-4 col-lg-3">
          <FormInput
            name="City Name"
            label="City Name"
            labelClassName="required"
            value={cityid}
            onChange={handleCityid}
            placeholder="City Name"
            errorText={cityyidError}
            containerClass="mb-2"
            disabled={showViewForm}
            type="select"
          >
            <option value="">Select City</option>
            {CityList.map((a, i) => {
              return (
                <Option value={a.id} key={i}>
                  {a.cityname}
                </Option>
              );
            })}
          </FormInput>
        </div>

        <div className="col-sm-6 col-md-4 col-lg-3">
          <FormInput
            name=" Street"
            label=" Street"
            labelClassName="required"
            value={street}
            onChange={handleStreet}
            placeholder=" Street"
            errorText={streetError}
            containerClass="mb-2"
            disabled={showViewForm}
          />
        </div>

        <div className="col-sm-6 col-md-4 col-lg-3">
          <FormInput
            name=" Pincode"
            label=" Pincode"
            labelClassName="required"
            value={pincode}
            onChange={handlePincode}
            placeholder=" Pincode"
            errorText={pincodeError}
            containerClass="mb-2"
            disabled={showViewForm}
          />
        </div>
        <div className="col-sm-6 col-md-4 col-lg-3">
          <FormInput
            name=" Logo Path"
            label=" Logo Path"
            labelClassName="required"
            value={logopath}
            onChange={handleLogopath}
            placeholder=" Logo Path"
            errorText={logopathError}
            containerClass="mb-2"
            disabled={showViewForm}
          />
        </div>
        <div className="col-sm-6 col-md-4 col-lg-3">
          <FormInput
            name=" Logo Url"
            label=" Logo Url"
            labelClassName="required"
            value={logourl}
            onChange={handleLogourl}
            placeholder=" Logo Url"
            errorText={logourlError}
            containerClass="mb-2"
            disabled={showViewForm}
          />
        </div>
        <div className="col-sm-6 col-md-4 col-lg-3">
          <FormInput
            name="Status"
            label="Status"
            labelClassName="required"
            value={active}
            onChange={handleActive}
            placeholder="Status"
            errorText={activeError}
            containerClass="mb-2"
            disabled={showViewForm}
            type="select"
          >
            <option value="">Select</option>
            {ACTIVE_DROPS.map((a, i) => {
              return (
                <Option value={a.value?.toString()} key={i}>
                  {a.text}
                </Option>
              );
            })}
          </FormInput>
        </div>
        <div className="col-sm-6 col-md-4 col-lg-3">
          <FormInput
            name=" Live From"
            label=" Live From"
            labelClassName="required"
            value={livefrom}
            max={today()}
            onChange={handleLivefrom}
            placeholder=" Live From"
            errorText={livefromError}
            containerClass="mb-2"
            type="date"
            disabled={showViewForm}
          />
        </div>

        {/* <div className='col-sm-6 col-md-4 col-lg-3'>
                <div className="form-control border-0">
                    <label className="pb-2">Site Logo</label>
                    <div className="border rounded border-2 border-secondray logo-lg pt-0 pb-1 m-0 w-75">
                        <img src={LogoDark} alt="" height="22" />
                    </div>
                    <div className="hstack gap-1 mt-1">
                        <button className="btn btn-secondary">Browser</button>
                        <button className="btn btn-secondary">Clear</button>
                    </div>
                </div>
            </div> */}
      </div>

      {(formType === FormTypeList.ADD || formType === FormTypeList.EDIT) && (
        <div className="d-flex justify-content-end hstack gap-1 mt-2">
          <button className="btn btn-secondary" onClick={handleClear}>
            Clear
          </button>
          {!loading ? (
            <button className="btn btn-secondary" onClick={handleSave}>
              Save
            </button>
          ) : (
            <button className="btn btn-secondary" disabled>
              <i className="fa fa-spinner fa-spin"></i> Saving...
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default SiteForm;
