import { nanoid } from "@reduxjs/toolkit";
import { ChangeEventHandler, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../../components/Modal";
import Option from "../../components/Option";
import FloorForm from "../../components/admin/FloorForm";
import { FormInput } from "../../components/custom/components";
import AdminTable, {
  Tbody,
  Thead,
} from "../../components/custom/components/AdminTable";
import AdminLayout from "../../components/custom/layouts/AdminLayout";
import { ToastTypes, useToast } from "../../components/toast/ToastProvider";
import { FormType, FormTypeList } from "../../config";
import {
  BuildingType,
  fetchBuilding,
} from "../../redux/actions/buildingActions";
import {
  FloorType,
  deleteFloor,
  fetchFloor,
} from "../../redux/actions/floorActions";
import { PortfolioType } from "../../redux/actions/portfolioActions";
import { SiteType } from "../../redux/actions/siteActions";
import { AppDispatch, StoreState } from "../../redux/store";
import DeleteConfirmationForm from "../../components/admin/DeleteConfirmationForm";
import { useLocation, useSearchParams } from "react-router-dom";
import { AuthAdminType } from "../../redux/actions/authAdminActions";
import { AdminLog } from "../../config/common";

type HandleDelete = (value: FloorType) => void;

const FloorManagement = () => {
  const toast = useToast();
  const dispatch = useDispatch<AppDispatch>();
  const pageName = useLocation()
  const authAdmin = useSelector<StoreState, AuthAdminType | null>(
    (state) => state.authAdmin
  );
  const adminAuthendicated =
  typeof authAdmin?.["auth-token"] === "string" &&
  authAdmin?.["auth-token"] !== undefined &&
  authAdmin?.["auth-token"] !== "";
  const FloorList = useSelector<StoreState, Array<FloorType>>(
    (state) => state.floor
  );
  const siteList = useSelector<StoreState, Array<SiteType>>(
    (state) => state.site
  );
  const BuildingList = useSelector<StoreState, Array<BuildingType>>(
    (state) => state.building
  );
  const portfolioList = useSelector<StoreState, Array<PortfolioType>>(
    (state) => state.portfolio
  );
  const filteredPortfolio = portfolioList.filter((a) => a.businessmodel !== 1);

  const [portfolioId, setportfolioId] = useState<string>("");
  const [portfolioIdError, setportfolioIdError] = useState<string>("");

  const [showForm, setShowForm] = useState<boolean>(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] =
    useState<boolean>(false);
  const [formType, setFormType] = useState<FormType>(FormTypeList.ADD);
  const [editData, setEditData] = useState<FloorType>();
  const [deleteData, setDeleteData] = useState<FloorType>();
  const [showViewForm, setShowViewForm] = useState<boolean>(false);
  const [id, setId] = useState<number>();

  const [searchQuery, setSearchQuery] = useSearchParams();
  const searchTerm = searchQuery.get("search") || "";

  const searchHandler: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (!portfolioId) {
      toast("Porfolio required", ToastTypes.ERROR);
      setSearchQuery({ search: "" });
      return;
    }
    setSearchQuery({ search: e.target.value });
  };

  const searchFloorHandler = (item: any, searchTerm: string) => {
    return searchTerm !== ""
      ? new RegExp(`^${searchTerm}|${searchTerm}`, "gi").test(
          item[1]["text"] + " " + item[2]["text"] + " " + item[3]["text"]
        )
      : true || [];
  };

  const getTableData = () => {
    if (FloorList === undefined) {
      return { tbody: [], thead: [] };
    }
    var tableHead: Thead[] = [
      { id: "S.No", children: "S.No", text: "S.No" },
      { id: "Center Name", children: "Center Name", text: "Center Name" },
      { id: "Building Name", children: "Building Name", text: "Building Name" },
      { id: "Floor Name", children: "Floor Name", text: "Floor Name" },
      { id: "Floor Code", children: "Floor Code", text: "Floor Code" },
      { id: "Action", children: "Action", text: "Action" },
    ];
    var tableBody: Tbody[][] = [];
    var tableBodyFiltered: Tbody[][] = [];

    if (portfolioId) {
      FloorList.forEach((item, i) => {
        let index = siteList.findIndex((c, i) => {
          return c.id === item.centerid;
        });
        var cname = siteList[index]?.name;
        let bindex = BuildingList.findIndex((c, i) => {
          return c.id === item.buildingid;
        });
        var buildingname = BuildingList[bindex]?.buildingname;

        var tBody: Tbody[] = [];
        tBody.push(
          {
            id: nanoid(),
            theadId: "S.No",
            children: i + 1,
            text: (i + 1)?.toString(),
          },
          {
            id: nanoid(),
            theadId: "Center Name",
            children: cname,
            text: cname,
            className: "text-truncate",
          },
          {
            id: nanoid(),
            theadId: "Building Name",
            children: buildingname,
            text: buildingname,
            className: "text-truncate",
          },
          {
            id: nanoid(),
            theadId: "Floor Name",
            children: item.floorname,
            text: item.floorname,
            className: "text-truncate",
          },
          {
            id: nanoid(),
            theadId: "Floor Code",
            children: item.floorcode,
            text: item.floorcode,
          },
          {
            id: nanoid(),
            theadId: "Action",
            children: "action",
            className: "d-none",
            text: item.id?.toString(),
          }
        );
        tableBody.push(tBody);
      });
      if (searchTerm?.length > 0) {
        tableBodyFiltered = tableBody.filter((item) =>
          searchFloorHandler(item, searchTerm)
        );
      } else {
        tableBodyFiltered = tableBody;
      }

      if (FloorList !== undefined) {
        return { tbody: tableBodyFiltered, thead: tableHead };
      }
    }
    return { tbody: tableBody, thead: tableHead };
  };

  const handlePfid: ChangeEventHandler<HTMLInputElement> = (e) => {
    setportfolioId(e.target.value);
    setportfolioIdError("");
  };

  const handleAdd = () => {
    if (!portfolioId) toast("Portfolio required", ToastTypes.ERROR);
    else {
      setShowForm(true)
      if(adminAuthendicated){
        const logProps1 = {
          action: 'Create',
          event: `${pageName.pathname.replace("\\", " ")} page | Create Floor Clicked`
        };
        AdminLog(logProps1)
      }
    }
  };

  const handleView = () => {
    setFormType(FormTypeList.VIEW);
    if (id !== undefined) {
      var obj = FloorList.find((c) => c.id === id);
      var buildingObj = BuildingList.find((d)=>d.id===obj?.buildingid)
      setShowForm(true);
      setEditData(obj);
      setShowViewForm(true);
      if(adminAuthendicated){
        const logProps1 = {
          action: 'View',
          event: `${pageName.pathname.replace("\\", " ")} page | Building - ${buildingObj?.buildingname}, Floor - ${obj?.floorname} Viewed | View Clicked`
        };
        AdminLog(logProps1)
      }
    }
  };

  const handleDeleteConfirmation = () => {
    setFormType(FormTypeList.DELETE);
    if (id !== undefined) {
      var obj = FloorList.find((c) => c.id === id);
      setShowDeleteConfirmation(true);
      setDeleteData(obj);
    }
  };

  const handleEdit = () => {
    setFormType(FormTypeList.EDIT);
    if (id !== undefined) {
      var obj = FloorList.find((c) => c.id === id);
      setEditData(obj);
      setShowForm(true);
      if(adminAuthendicated){
        const logProps1 = {
          action: 'Edit',
          event: `${pageName.pathname.replace("\\", " ")} page | Floor - ${obj?.floorname}  | Edit Clicked`
        };
        AdminLog(logProps1)
      }
    }
  };

  const handleDelete: HandleDelete = (value) => {
    if (value.id !== undefined) {
      dispatch(deleteFloor(value.id, portfolioId))
        .then((text) => {
          toast(text, ToastTypes.SUCCESS);
          var obj = FloorList.find((c) => c.id === id);
          if(adminAuthendicated){
            const logProps1 = {
              action: 'Delete',
              event: `${pageName.pathname.replace("\\", " ")} page | Floor - ${obj?.floorname} | Delete Clicked`
            };
            AdminLog(logProps1)
          }
          handleFormClose();
        })
        .catch((text) => {});
    }
  };

  const handleFormClose = () => {
    setShowForm(false);
    setFormType(FormTypeList.ADD);
    setEditData(undefined);
    setId(undefined);
    setShowViewForm(false);
    setShowDeleteConfirmation(false);
  };

  useEffect(() => {
    if (id !== undefined && formType === FormTypeList.VIEW) handleView();
    else if (id !== undefined && formType === FormTypeList.EDIT) handleEdit();
    else if (id !== undefined && formType === FormTypeList.DELETE)
      handleDeleteConfirmation();
  }, [id, formType]);

  useEffect(() => {
    if (portfolioId) {
      dispatch(fetchFloor(portfolioId));
      dispatch(fetchBuilding(portfolioId));
    }
  }, [portfolioId]);

  useEffect(()=>{
    if(adminAuthendicated){
    const logProps1 = {
      action:`Visit`,
      event: `${pageName.pathname.replace("\\", " ")} page | Visited `
    };
    AdminLog(logProps1)
  }
  },[])

  return (
    <>
      <Modal
        headerText={formType + " FLOOR"}
        visible={showForm}
        onClose={handleFormClose}
        size="xl"
        centered
      >
        <FloorForm
          formType={formType}
          editData={editData}
          onSave={handleFormClose}
          showViewForm={showViewForm}
          pfId={portfolioId}
        />
      </Modal>
      <Modal
        headerText={formType + " FLOOR"}
        visible={showDeleteConfirmation}
        onClose={handleFormClose}
        size="lg"
        centered
      >
        <DeleteConfirmationForm
          deleteLabel={
            "Do you really want to delete the records with the floor - " +
            deleteData?.floorname
          }
          onCancel={handleFormClose}
          onDelete={() => handleDelete(deleteData!)}
        />
      </Modal>
      <AdminLayout>
        <div className="row">
          <div className="col-6 hstack mt-4">
            <label htmlFor="">Portfolio :</label>
            <FormInput
              name="Portfolio"
              label=""
              labelClassName="required"
              value={portfolioId}
              onChange={handlePfid}
              placeholder="Portfolio"
              errorText={portfolioIdError}
              containerClass="w-50"
              disabled={showViewForm}
              type="select"
            >
              <option value="">Select</option>
              {filteredPortfolio.map((a, i) => {
                return (
                  <Option value={a.id} key={i}>
                    {a.name}
                  </Option>
                );
              })}
            </FormInput>
          </div>
        </div>
        <AdminTable
          addBtnText="Create New Floor"
          onAdd={handleAdd}
          onEdit={handleEdit}
          onDelete={handleDeleteConfirmation}
          onView={handleView}
          search={
            portfolioId !== "" && portfolioId !== undefined
              ? searchHandler
              : undefined
          }
          pageTitle="Floor Management"
          path="/admin/floor_management"
          // data={data}
          setId={setId}
          {...getTableData()}
        />
      </AdminLayout>
    </>
  );
};

export default FloorManagement;
