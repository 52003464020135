import { nanoid } from "@reduxjs/toolkit";
import { ChangeEventHandler, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../../components/Modal";
import UsersForm from "../../components/admin/UsersForm";
import AdminTable from "../../components/custom/components/AdminTable";
import {
  Tbody,
  Thead,
} from "../../components/custom/components/TradeCategoryPerformanceTable";
import AdminLayout from "../../components/custom/layouts/AdminLayout";
import { ToastTypes, useToast } from "../../components/toast/ToastProvider";
import { FormType, FormTypeList } from "../../config";
import { BrandType } from "../../redux/actions/brandActions";
import { CompanyType } from "../../redux/actions/companyActions";
import { PortfolioType } from "../../redux/actions/portfolioActions";
import { SiteType, fetchUsersSite } from "../../redux/actions/siteActions";
import { UsersType, deleteUsers } from "../../redux/actions/usersActions";
import { AppDispatch, StoreState } from "../../redux/store";
import { useLocation, useSearchParams } from "react-router-dom";
import { AuthAdminType } from "../../redux/actions/authAdminActions";
import { AdminLog } from "../../config/common";
import { fetchRoles } from "../../redux/actions/userRoleActions";

// interface TableBody {
//     sno: number
//     loginid: string
//     name: string
//     password: string
//     status: ActiveTypes | boolean
//     email: string
//     role: string
//     companyId: number
//     portfolioId: number
//     siteId: number
//     brandId: number
//     companyName?: string
//     portfolioName?: string
//     siteName?: string
//     brandName?: string
//     onEdit: MouseEventHandler<HTMLButtonElement>
//     onDelete: MouseEventHandler<HTMLButtonElement>
//     onView: MouseEventHandler<HTMLButtonElement>
// }

// type HandleEdit = (value: UsersType) => void
// type HandleDelete = (value: UsersType) => void
// type HandleView = (value: UsersType) => void

const UserManagement = () => {
  const toast = useToast();
  const dispatch = useDispatch<AppDispatch>();
  const pageName = useLocation()
  const authAdmin = useSelector<StoreState, AuthAdminType | null>(
    (state) => state.authAdmin
  );
  const adminAuthendicated =
  typeof authAdmin?.["auth-token"] === "string" &&
  authAdmin?.["auth-token"] !== undefined &&
  authAdmin?.["auth-token"] !== "";
  const UsersList = useSelector<StoreState, Array<UsersType>>(
    (state) => state.users
  );
  const BrandList = useSelector<StoreState, Array<BrandType>>(
    (state) => state.brand
  );
  const PortfolioList = useSelector<StoreState, Array<PortfolioType>>(
    (state) => state.portfolio
  );
  const SiteList = useSelector<StoreState, Array<SiteType>>(
    (state) => state.site
  );
  const CompanyList = useSelector<StoreState, Array<CompanyType>>(
    (state) => state.company
  );

  const [showForm, setShowForm] = useState<boolean>(false);
  const [formType, setFormType] = useState<FormType>(FormTypeList.ADD);
  const [editData, setEditData] = useState<UsersType>();
  const [showViewForm, setShowViewForm] = useState<boolean>(false);
  const [id, setId] = useState<number>();

  const [searchQuery, setSearchQuery] = useSearchParams();
  const searchTerm = searchQuery.get("search") || "";

  const searchHandler: ChangeEventHandler<HTMLInputElement> = (e) => {
    setSearchQuery({ search: e.target.value });
  };

  const searchUserHandler = (item: any, searchTerm: string) => {
    return searchTerm !== ""
      ? new RegExp(`^${searchTerm}|${searchTerm}`, "gi").test(
          item[1]["text"] + " " + item[2]["text"]
        )
      : true || [];
  };

  const getTableData = () => {
    if (UsersList === undefined) {
      return { tbody: [], thead: [] };
    }
    var tableHead: Thead[] = [
      { id: "S.No", children: "S.No", text: "S.No" },
      { id: "Login Id", children: "Login Id", text: "Login Id" },
      { id: "Name", children: "Name", text: "Name" },
      { id: "Status", children: "Status", text: "Status" },
      { id: "Email", children: "Email", text: "Email" },
      { id: "Role", children: "Role", text: "Role" },
      { id: "Company Name", children: "Company Name", text: "Company Name" },
      {
        id: "Portfolio Name",
        children: "Portfolio Name",
        text: "Portfolio Name",
      },
      { id: "Cemter Name", children: "Center Name", text: "Center Name" },
      { id: "Brand Name", children: "Brand Name", text: "Brand Name" },
      { id: "Action", children: "Action", text: "Action" },
    ];
    var tableBody: Tbody[][] = [];
    var tableBodyFiltered: Tbody[][] = [];

    UsersList.forEach((item, i) => {
      let cindex = CompanyList.findIndex((c, i) => {
        return c.id === item.companyId;
      });
      // let pindex = PortfolioList.findIndex((c, i) => {
      //     return c.id === item.portfolioId
      // })
      const port =
        item?.portfolioId !== undefined && item?.portfolioId !== null
          ? item.portfolioId.split(",")
          : [];

      let portfolioData = port
        ?.map((pe) => {
          return PortfolioList.filter(
            (f) => f?.id?.toString() === pe?.toString()
          );
        })
        .flat(1);

      // let sindex = SiteList.findIndex((c, i) => {
      //     return c.id === item.siteId
      // })
      const st =
        item?.siteId !== undefined && item?.siteId !== null
          ? item.siteId.split(",")
          : [];

      let sdata = st
        ?.map((pe) => {
          return SiteList.filter((f) => f?.id?.toString() === pe?.toString());
        })
        .flat(1);
      // let bindex = BrandList.findIndex((c, i) => {
      //     return c.id === item.brandId
      // })
      const bnd =
        item?.brandId !== undefined && item?.brandId !== null
          ? item.brandId.split(",")
          : [];
      let brandData = bnd
        ?.map((pe) => {
          return BrandList.filter((f) => f?.id?.toString() === pe?.toString());
        })
        .flat(1);

      var cname = CompanyList[cindex]?.name;
      // var pname = PortfolioList[pindex]?.name
      // var sname = SiteList[sindex]?.name
      // var bname = BrandList[bindex]?.name
      var pname = "";
      portfolioData.forEach((p, i) => {
        if (i === 0) pname = pname + p.name;
        else pname = pname + "," + p.name;
      });

      var sname = "";
      sdata.forEach((s, i) => {
        if (i === 0) sname = sname + s.name;
        else sname = sname + "," + s.name;
      });

      var bname = "";
      brandData.forEach((b, i) => {
        if (i === 0) bname = bname + b.name;
        else bname = bname + "," + b.name;
      });

      var tBody: Tbody[] = [];

      tBody.push(
        {
          id: nanoid(),
          theadId: "S.No",
          children: i + 1,
          text: (i + 1)?.toString(),
        },
        {
          id: nanoid(),
          theadId: "Login Id",
          children: item.loginid,
          text: item.loginid,
          className: "text-truncate",
        },
        {
          id: nanoid(),
          theadId: "Name",
          children: item.name,
          text: item.name,
          className: "text-truncate",
        },
        {
          id: nanoid(),
          theadId: "Status",
          children: item.status === "active" ? "Active" : "InActive",
          text: item.status === "active" ? "Active" : "InActive",
        },
        {
          id: nanoid(),
          theadId: "Email",
          children: item.email,
          text: item.email,
        },
        {
          id: nanoid(),
          theadId: "Role",
          children: item.role,
          text: item.role,
        },
        {
          id: nanoid(),
          theadId: "Company Name",
          children: cname,
          text: cname,
        },
        {
          id: nanoid(),
          theadId: "Portfolio Name",
          children: pname,
          text: pname,
        },
        {
          id: nanoid(),
          theadId: "Center Name",
          children: sname,
          text: sname,
        },
        {
          id: nanoid(),
          theadId: "Brand Name",
          children: bname,
          text: bname,
        },
        {
          id: nanoid(),
          theadId: "Action",
          children: "action",
          className: "d-none",
          text: item.uid?.toString(),
        }
      );
      tableBody.push(tBody);
      // })
    });

    if (searchTerm?.length > 0) {
      tableBodyFiltered = tableBody.filter((item) =>
        searchUserHandler(item, searchTerm)
      );
    } else {
      tableBodyFiltered = tableBody;
    }

    if (UsersList !== undefined) {
      return { tbody: tableBodyFiltered, thead: tableHead };
    }
  };
  const handleAdd = () => {
    setShowForm(true);
    if(adminAuthendicated){
      const logProps1 = {
        action: 'Create',
        event: `${pageName.pathname.replace("\\", " ")} page | Create User Clicked`
      };
      AdminLog(logProps1)
    }
  };

  const handleView = () => {
    setFormType(FormTypeList.VIEW);
    if (id !== undefined) {
      var obj = UsersList.find((c) => c.uid === id);
      setShowForm(true);
      setEditData(obj);
      setShowViewForm(true);
      if(adminAuthendicated){
        const logProps1 = {
          action: 'View',
          event: `${pageName.pathname.replace("\\", " ")} page |  ${obj?.name} User Viewed | View Clicked`
        };
        AdminLog(logProps1)
      }
    }
  };

  const handleEdit = () => {
    setFormType(FormTypeList.EDIT);
    if (id !== undefined) {
      var obj = UsersList.find((c) => c.uid === id);
      setEditData(obj);
      setShowForm(true);
      if(adminAuthendicated){
        const logProps1 = {
          action: 'Edit',
          event: `${pageName.pathname.replace("\\", " ")} page | User - ${obj?.name} | Edit Clicked`
        };
        AdminLog(logProps1)
      }
    }
  };

  const handleDelete = () => {
    setFormType(FormTypeList.DELETE);
    if (id !== undefined) {
      dispatch(deleteUsers(id))
        .then((text) => {
          toast(text, ToastTypes.SUCCESS);
        })
        .catch((text) => {});
    }
  };

  const handleFormClose = () => {
    setShowForm(false);
    setFormType(FormTypeList.ADD);
    setEditData(undefined);
    setId(undefined);
    setShowViewForm(false);
  };

  useEffect(() => {
    if (id !== undefined && formType === FormTypeList.VIEW) handleView();
    else if (id !== undefined && formType === FormTypeList.EDIT) handleEdit();
    else if (id !== undefined && formType === FormTypeList.DELETE)
      handleDelete();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, formType]);

  

 
  
  useEffect(() => {
      dispatch(fetchUsersSite())
  }, [])

  useEffect(()=>{
    if(adminAuthendicated){
      dispatch(fetchRoles())
    const logProps1 = {
      action:`Visit`,
      event: `${pageName.pathname.replace("\\", " ")} page | Visited `
    };
    AdminLog(logProps1)
  }
  },[])

  return (
    <>
      <Modal
        headerText={formType + " USER"}
        visible={showForm}
        onClose={handleFormClose}
        size="xl"
        centered
      >
        <UsersForm
          formType={formType}
          editData={editData}
          onSave={handleFormClose}
          showViewForm={showViewForm}
        />
      </Modal>
      <AdminLayout>
        {/* <PageTitle
                title='User Management'
                buttonText='Create New User'
                onButtonClick={handleAdd}
            /> */}
        <AdminTable
          addBtnText="Create New User"
          onAdd={handleAdd}
          onEdit={handleEdit}
          // onDelete={handleDelete}
          search={searchHandler}
          onView={handleView}
          pageTitle="User Management"
          path="/admin/user_management"
          // data={data}
          setId={setId}
          {...getTableData()}
        />
        {/* <div className="card mt-3">
                <div className="card-body">
                    <div className='row'>
                        <div className="col-lg-6 col-sm-12">
                            <h5 className="header-title mb-3 float-start"><i className="fe-align-justify me-1 text-primary"></i>User Management</h5>
                        </div>
                        <div className="col-lg-6 col-sm-12 text-end mb-1">
                            <button type="button" onClick={handleAdd} className={`me-1 btn btn-xs btn-secondary`}>Create New User</button>
                        </div>
                    </div>
                    <hr className='hr mt-0' />
                    <div className='table-wrapper'>
                        <table className='table colored'>
                            <thead>
                                <tr>
                                    <th className='text-truncate align-middle'>S.No</th>
                                    <th className='text-truncate align-middle'>Login Id</th>
                                    <th className='text-truncate align-middle'>Name</th>
                                    <th className='text-truncate align-middle'>Status</th>
                                    <th className='text-truncate align-middle text-center'>Email</th>
                                    <th className='text-truncate align-middle'>Role</th>
                                    <th className='text-truncate align-middle'>Company Name</th>
                                    <th className='text-truncate align-middle'>Portfolio Name</th>
                                    <th className='text-truncate align-middle'>Site Name</th>
                                    <th className='text-truncate align-middle'>Brand Name</th>
                                    <th className='text-truncate align-middle text-center'>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {UsersList.map((u, i) => {
                                    let cindex = CompanyList.findIndex((c, i) => {
                                        return c.id === u.companyId
                                    })
                                    let pindex = PortfolioList.findIndex((c, i) => {
                                        return c.id === u.portfolioId
                                    })
                                    let sindex = SiteList.findIndex((c, i) => {
                                        return c.id === u.siteId
                                    })
                                    let bindex = BrandList.findIndex((c, i) => {
                                        return c.id === u.brandId
                                    })
                                    var cname = CompanyList[cindex]?.name
                                    var pname = PortfolioList[pindex]?.name
                                    var sname = SiteList[sindex]?.name
                                    var bname = BrandList[bindex]?.name

                                    return <UsersBody
                                        sno={i + 1}
                                        loginid={u.loginid !== undefined ? u.loginid : ''}
                                        name={u.name}
                                        password={u.password !== undefined ? u.password : ''}
                                        status={u.status}
                                        email={u.email}
                                        role={u.role}
                                        companyId={u.companyId}
                                        portfolioId={u.portfolioId}
                                        siteId={u.siteId}
                                        brandId={u.brandId}
                                        companyName={cname}
                                        portfolioName={pname}
                                        siteName={sname}
                                        brandName={bname}
                                        key={i}
                                        onEdit={() => handleEdit(u)}
                                        onDelete={() => handleDelete(u)}
                                        onView={() => handleView(u)}
                                    />
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div> */}
      </AdminLayout>
    </>
  );
};

// const UsersBody = ({ sno, loginid, name, password, status, email, role, companyId, portfolioId, siteId, brandId, onView, onEdit, onDelete, companyName, portfolioName, siteName, brandName }: TableBody) => {
//     return <tr>
//         <td className='align-middle'>{sno}</td>
//         <td className='align-middle'>{loginid}</td>
//         <td className='align-middle'>{name}</td>
//         {/* <td className='align-middle'>{password}</td> */}
//         <td className='align-middle'>{status ? "Active" : "InActive"}</td>
//         <td className='align-middle'>{email}</td>
//         <td className='align-middle'>{role}</td>
//         <td className='align-middle text-center'>{companyName}</td>
//         <td className='align-middle text-center'>{portfolioName}</td>
//         <td className='align-middle text-center'>{siteName}</td>
//         <td className='align-middle text-center'>{brandName}</td>
//         <td>
//             <div className='hstack gap-1'>
//                 <button className='btn btn-sm btn-secondary' onClick={onEdit}>Edit</button>
//                 {/* <button className='btn btn-sm btn-danger' onClick={onDelete}>Delete</button> */}
//                 <button className='btn btn-sm btn-secondary' onClick={onView}>View</button>
//             </div>
//         </td>
//     </tr>
// }

export default UserManagement;
