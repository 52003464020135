import { Dispatch } from "redux";
import {
  BrandContactDetails,
  TenantContactDetails,
} from "../../components/context/TenantManagerContext";
import api, { APIResponse, getAxiosRequestConfig } from "../../config/api";
import { GetState } from "../store";
import { ITDetailsTypes } from "./ITDetailsActions";

export interface TenantManagerType {
  tenantid?: number;
  displayid?: string;
  portfolioid?: string;
  centerid?: string;
  franchise?: boolean;
  company?: string;
  companyName?: string;
  brand?: string;
  brandName?: string;
  tradeCategory?: string;
  tradeCategoryName?: string;
  tradeSubCategory?: string;
  tradeSubCategoryName?: string;
  tenantCategory?: string;
  tenantCategoryName?: string;
  tenantManagerName?: string;
  //lease form
  leaseid?: string;
  carpetArea?: string;
  chargeableArea?: string;
  landmark?: string;
  buildingTerminal?: string;
  location?: string;
  paxType?: string;
  unitName?: string;
  //IT form
  posType?: ITDetailsTypes;
  posName?: string;
  ITTeam?: ITDetailsTypes;
  //tenant contact form
  tenantContactDetails?: TenantContactDetails[];
  //brand contact form
  brandContactDetails?: BrandContactDetails[];
}

// export const TenantManagerSampleData = [
//     {
//         id: '1',
//         tenantManagerName: 'Manager-1',

//     },
//     {
//         id: '2',
//         tenantManagerName: 'Manager-2',

//     },

// ]

export enum TenantManagerActionsList {
  ADD_TENANTMANAGER = "ADD_TENANTMANAGER",
  EDIT_TENANTMANAGER = "EDIT_TENANTMANAGER",
  FETCH_TENANTMANAGER = "FETCH_TENANTMANAGER",
  DELETE_TENANTMANAGER = "DELETE_TENANTMANAGER",
}

export interface DeleteTenantManager {
  id: number;
}

export interface AddTenantManagerAction {
  type: TenantManagerActionsList.ADD_TENANTMANAGER;
  data: TenantManagerType;
}

export interface EditTenantManagerAction {
  type: TenantManagerActionsList.EDIT_TENANTMANAGER;
  data: TenantManagerType;
  id: number;
}

export interface FetchTenantManagerAction {
  type: TenantManagerActionsList.FETCH_TENANTMANAGER;
  data: TenantManagerType[];
}

export interface DeleteTenantManagerAction {
  type: TenantManagerActionsList.DELETE_TENANTMANAGER;
  data: DeleteTenantManager;
}

export type TenantManagerActions =
  | AddTenantManagerAction
  | EditTenantManagerAction
  | FetchTenantManagerAction
  | DeleteTenantManagerAction;

export const addTenantManager = (data: TenantManagerType) => {
  return async (dispatch: Dispatch, getState: GetState) => {
    const token =
      getState().authAdmin?.["auth-token"]!;
    const config = getAxiosRequestConfig(token);
    return api
      .post<APIResponse<TenantManagerType>>("tenantManager/", data, config)
      .then((response) => {
        if (response.status === 200 && response.data.data) {
          dispatch<AddTenantManagerAction>({
            type: TenantManagerActionsList.ADD_TENANTMANAGER,
            data: response.data.data,
          });
          return Promise.resolve(
            response.data.message
              ? response.data.message
              : "Tenant Manager added"
          );
        } else {
          // throw { response };
          throw new Error("Unable to create")
        }
      })
      .catch((error) => {
        return Promise.reject(
          error.response
            ? error.response.data.message
              ? error.response.data.message
              : "Unable to create"
            : "Unable to create"
        );
      });
  };
};

export const editTenantManager = (data: TenantManagerType, id: number) => {
  return async (dispatch: Dispatch, getState: GetState) => {
    const token =
      getState().authAdmin?.["auth-token"]!;
    const config = getAxiosRequestConfig(token);
    return api
      .put<APIResponse<TenantManagerType>>(`tenantManager/${id}`, data, config)
      .then((response) => {
        if (response.status === 200 && response.data.data) {
          dispatch<EditTenantManagerAction>({
            type: TenantManagerActionsList.EDIT_TENANTMANAGER,
            data: data,
            id: id,
          });
          return Promise.resolve(
            response.data.message
              ? response.data.message
              : "Tenant Manager Updated"
          );
        } else {
          throw new Error("Unable to update")
        }
      })
      .catch((error) => {
        return Promise.reject(
          error.response
            ? error.response.data.message
              ? error.response.data.message
              : "Unable to update"
            : "Unable to update"
        );
      });
  };
};

export const fetchTenantManager = (portfolioId: string) => {
  return async (dispatch: Dispatch, getState: GetState) => {
    const token =
      getState().authAdmin?.["auth-token"]!;
    const config = getAxiosRequestConfig(token);
    return api
      .get<APIResponse<TenantManagerType[]>>(
        `tenantManager/${portfolioId}`,
        config
      )
      .then((response) => {
        if (response.status === 200 && response.data.data) {
          dispatch<FetchTenantManagerAction>({
            type: TenantManagerActionsList.FETCH_TENANTMANAGER,
            data: response.data.data,
          });
          return Promise.resolve(
            response.data.message ? response.data.message : "Fetch Successfull"
          );
        } else {
          throw new Error("Unable to fetch")
        }
      })
      .catch((error) => {
        return Promise.reject(
          error.response
            ? error.response.data.message
              ? error.response.data.message
              : "Unable to fetch"
            : "Unable to fetch"
        );
      });
  };
};

export const deleteTenantManager = (id: number, portfolioId: string) => {
  return async (dispatch: Dispatch, getState: GetState) => {
    const token =
      getState().authAdmin?.["auth-token"]!;
    const config = getAxiosRequestConfig(token);
    return api
      .delete<APIResponse<TenantManagerType>>(
        `tenantManager/${portfolioId}/${id}`,
        config
      )
      .then((response) => {
        if (response.status === 200) {
          dispatch<DeleteTenantManagerAction>({
            type: TenantManagerActionsList.DELETE_TENANTMANAGER,
            data: { id },
          });
          return Promise.resolve(
            response.data.message
              ? response.data.message
              : " Tenant Manager Deleted"
          );
        } else {
          throw new Error("Unable to delete")
        }
      })
      .catch((error) => {
        return Promise.reject(
          error.response
            ? error.response.data.message
              ? error.response.data.message
              : "Unable to delete"
            : "Unable to delete"
        );
      });
  };
};
