import { combineReducers } from "redux";
import authAdminReducer from "./authAdminReducer";
import { BrandCompanyReducer } from "./brandCompanyReducer";
import { BrandReducer } from "./brandReducer";
import { BuildingReducer } from "./buildingReducer";
import { CompanyContactReducer } from "./companyContactReducer";
import { CompanyReducer } from "./companyReducer";
import { ContactReducer } from "./contactReducer";
import { CountryReducer } from "./countryReducer";
import { CredentialReducer } from "./credentialReducer";
import { FloorReducer } from "./floorReducer";
import { NotificationReducer } from "./notificationReducer";
import { PortfolioDBReducer } from "./portfolioDBReducer";
import { PortfolioReducer } from "./portfolioReducer";
import { RoleReducer } from "./roleReducer";
import { SiteReducer } from "./siteReducer";
import { TenantManagerReducer } from "./tenantManagerReducer";
import { TenantCategoryReducer } from "./tenantReducer";
import { TradeCategoryReducer } from "./tradeCategoryReducer";
import { TradeSubCategoryReducer } from "./tradeSubCategoryReducer";
import { UsersReducer } from "./usersReducer";
import { SelectedOptionsReducer } from "./selectedOptionsReducer";
import { BrandCompanyMappingReducer } from "./brandCompanyMappingReducer";
import { OutletReducer } from "./outletReducer";
import { SessionReducer, AdminSessionReducer, RentReducer, RegionReducer, StateReducer, CityReducer } from "./commonReducer";
import { LogUserReducer } from './logUserReducer'
import { RolesReducer } from "./userRoleReducer";

const reducers = combineReducers({
  authAdmin: authAdminReducer,
  portfolio: PortfolioReducer,
  site: SiteReducer,
  brand: BrandReducer,
  brandCompany: BrandCompanyReducer,
  brandCompanyMapping: BrandCompanyMappingReducer,
  tradeCategory: TradeCategoryReducer,
  tradeSubCategory: TradeSubCategoryReducer,
  tenant: TenantCategoryReducer,
  tenantmanager: TenantManagerReducer,
  outlet: OutletReducer,
  contact: ContactReducer,
  company: CompanyReducer,
  users: UsersReducer,
  country: CountryReducer,
  portfolioDB: PortfolioDBReducer,
  role: RoleReducer,
  companyContact: CompanyContactReducer,
  notification: NotificationReducer,
  building: BuildingReducer,
  floor: FloorReducer,
  credential: CredentialReducer,
  selectedOption: SelectedOptionsReducer,
  rent: RentReducer,
  region: RegionReducer,
  state: StateReducer,
  city: CityReducer,
  session: SessionReducer,
  adminSession: AdminSessionReducer,
  LogUserReducer: LogUserReducer,
  roles: RolesReducer,
});

export default reducers;
