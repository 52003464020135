import React, { ChangeEventHandler, useEffect, useState } from "react";
import { nanoid } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import AdminTable from "../../components/custom/components/AdminTable";
import {Tbody, Thead} from "../../components/custom/components/TradeCategoryPerformanceTable";
import api, { APIResponse, getAxiosRequestConfig } from "../../config/api";
import { StoreState } from "../../redux/store";
import { AuthAdminType } from "../../redux/actions/authAdminActions";
import AdminLayout from "../../components/custom/layouts/AdminLayout";

interface UserLogType {
  loginid: string;
  name: string;
  apptype: string;
  logintime: string;
  logouttime: string;
  accesstime: string;
  ipaddress: string;
}

const AdminUserLogManagement = () => {
  const dispatch = useDispatch();
  const authAdmin = useSelector<StoreState, AuthAdminType | null>(
    (state) => state.authAdmin
  );
  const adminAuthendicated = authAdmin?.["auth-token"] ? authAdmin?.["auth-token"] : "";

  const [logs, setLogs] = useState<UserLogType[]>([]);
  const [searchQuery, setSearchQuery] = useSearchParams();
  const searchTerm = searchQuery.get("search") || "";

  useEffect(() => {
    const config = getAxiosRequestConfig(adminAuthendicated);
    if (adminAuthendicated !== undefined && adminAuthendicated !== "") {
      api
        .get<APIResponse<UserLogType[]>>(`userlogs`, config)
        .then((response) => {
          if (response.status === 200 && response.data.data) {
            const result = response.data.data;
            setLogs(result);
          }
        })
        .catch((error) => {});
    }
  }, [adminAuthendicated]);

  const searchHandler: ChangeEventHandler<HTMLInputElement> = (e) => {
    setSearchQuery({ search: e.target.value });
  };
  const searchUserLogHandler = (item: any, searchTerm: string) => {
    return searchTerm !== ""
      ? new RegExp(`^${searchTerm}|${searchTerm}`, "gi").test(
          item[1]["text"] + " " + item[2]["text"]
        )
      : true || [];
  };
  const getTableData = () => {
    var tableHead: Thead[] = [
      { id: "S.No", children: "S.No", text: "S.No" },
      { id: "Login Id", children: "Login Id", text: "Login Id" },
      { id: "Name", children: "Name", text: "Name" },
      { id: "App Type", children: "App Type", text: "App Type" },
      { id: "Login Time", children: "Login Time", text: "Login Time" },
      { id: "Logout Time", children: "Logout Time", text: "Logout Time" },
      { id: "Access Time", children: "Access Time", text: "Access Time" },
      { id: "Ip Address", children: "Ip Address", text: "Ip Address" },
    ];
    var tableBody: Tbody[][] = [];
    var tableBodyFiltered: Tbody[][] = [];
    logs.forEach((item, i) => {
      var tBody: Tbody[] = [];
      tBody.push(
        {
          id: nanoid(),
          theadId: "S.No",
          children: i + 1,
          text: (i + 1)?.toString(),
        },
        {
          id: nanoid(),
          theadId: "Login Id",
          children: item.loginid,
          text: item.loginid,
          className: "text-truncate",
        },
        {
          id: nanoid(),
          theadId: "Name",
          children: item.name,
          text: item.name,
          className: "text-truncate",
        },
        {
          id: nanoid(),
          theadId: "App Type",
          children: item.apptype,
          text: item.apptype,
        },
        {
          id: nanoid(),
          theadId: "Login Time",
          children: item.logintime,
          text: item.logintime,
        },
        {
          id: nanoid(),
          theadId: "Logout Time",
          children: item.logouttime,
          text: item.logouttime,
        },
        {
          id: nanoid(),
          theadId: "Access Time",
          children: item.accesstime,
          text: item.accesstime,
        },
        {
          id: nanoid(),
          theadId: "Ip Address",
          children: item.ipaddress,
          text: item.ipaddress,
        }
      );
      tableBody.push(tBody);
    });

    if (searchTerm?.length > 0) {
      tableBodyFiltered = tableBody.filter((item) =>
        searchUserLogHandler(item, searchTerm)
      );
    } else {
      tableBodyFiltered = tableBody;
    }
    return { tbody: tableBodyFiltered, thead: tableHead };
  };

  return (
    <>
    <AdminLayout>
        <AdminTable 
          addBtnText=""
          search={searchHandler}
          pageTitle="User Log Management"
          path="/admin/user-log-management"
          {...getTableData()}
        />
      </AdminLayout>
    </>
  )
}

export default AdminUserLogManagement;
