import { nanoid } from "@reduxjs/toolkit";
import { ChangeEventHandler, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../../components/Modal";
import TradeSubCategoryForm from "../../components/admin/TradeSubCategoryForm";
import {
  Tbody,
  Thead,
} from "../../components/custom/components/TradeCategoryPerformanceTable";
import AdminLayout from "../../components/custom/layouts/AdminLayout";
import { ToastTypes, useToast } from "../../components/toast/ToastProvider";
import { FormType, FormTypeList } from "../../config";
import { TradeCategoryType } from "../../redux/actions/tradeCategoryActions";
import {
  TradeSubCategoryType,
  deleteTradeSubCategory,
  fetchTradeSubCategory,
} from "../../redux/actions/tradeSubCategoryActions";
import { AppDispatch, StoreState } from "../../redux/store";
import DeleteConfirmationForm from "../../components/admin/DeleteConfirmationForm";
import { useLocation, useSearchParams } from "react-router-dom";
import AdminTable2 from "../../components/custom/components/AdminTable2";
import { AuthAdminType } from "../../redux/actions/authAdminActions";
import { AdminLog } from "../../config/common";

// interface TableBody {
//     sno: number
//     categoryname?: string
//     name: string
//     catid: number
//     active: ActiveTypes | boolean
//     onEdit: MouseEventHandler<HTMLButtonElement>
//     onDelete: MouseEventHandler<HTMLButtonElement>
//     onView: MouseEventHandler<HTMLButtonElement>
// }

// type HandleEdit = (value: TradeSubCategoryType) => void
type HandleDelete = (value: TradeSubCategoryType) => void;
// type HandleView = (value: TradeSubCategoryType) => void

const TradeSubCategory = () => {
  const toast = useToast();
  const dispatch = useDispatch<AppDispatch>();
  const pageName = useLocation()
  const authAdmin = useSelector<StoreState, AuthAdminType | null>(
    (state) => state.authAdmin
  );
  const adminAuthendicated =
  typeof authAdmin?.["auth-token"] === "string" &&
  authAdmin?.["auth-token"] !== undefined &&
  authAdmin?.["auth-token"] !== "";
  const TradeSubCategoryList = useSelector<
    StoreState,
    Array<TradeSubCategoryType>
  >((state) => state.tradeSubCategory);
  const TradeCategoryList = useSelector<StoreState, Array<TradeCategoryType>>(
    (state) => state.tradeCategory
  );

  const [showForm, setShowForm] = useState<boolean>(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] =
    useState<boolean>(false);
  const [formType, setFormType] = useState<FormType>(FormTypeList.ADD);
  const [editData, setEditData] = useState<TradeSubCategoryType>();
  const [deleteData, setDeleteData] = useState<TradeSubCategoryType>();
  const [showViewForm, setShowViewForm] = useState<boolean>(false);
  const [id, setId] = useState<number>();

  //search params
  const [searchQuery, setSearchQuery] = useSearchParams();
  const searchTerm = searchQuery.get("search") || "";

  const searchHandler: ChangeEventHandler<HTMLInputElement> = (e) => {
    setSearchQuery({ search: e.target.value });
  };

  const searchSubCatHandler = (item: any, searchTerm: string) => {
    return searchTerm !== ""
      ? new RegExp(`^${searchTerm}|${searchTerm}`, "gi").test(
          item[1]["text"] + " " + item[2]["text"]
        )
      : true || [];
  };

  const [tradeSubCategoryData, setTradeSubCategoryData] = useState<
    TradeSubCategoryType[]
  >([]);

  const limit = 20;
  const [offset, setOffset] = useState<number>(0);
  const [range, setRange] = useState<number[]>([]);
  const [length, setLength] = useState<number>(0);

  useEffect(() => {
    if (TradeSubCategoryList?.length > 0) {
      setLength(
        TradeSubCategoryList?.length ? TradeSubCategoryList?.length : 0
      );
    }
  }, [TradeSubCategoryList]);

  const handleApply = async (offsetVal = offset) => {
    if (TradeSubCategoryList?.length > 0) {
      const getData = {
        limit: limit?.toString(),
        offset: offsetVal?.toString(),
      };

      if (
        getData !== undefined &&
        getData.limit !== "" &&
        getData.offset !== "" &&
        getData.offset !== undefined &&
        getData.offset !== "NaN"
      ) {
        const filteredTradeSubCategoryList: TradeSubCategoryType[] =
          TradeSubCategoryList?.slice(offset, limit + offset);
        if (filteredTradeSubCategoryList?.length > 0) {
          setTradeSubCategoryData([...filteredTradeSubCategoryList]);
        } else {
          setTradeSubCategoryData([]);
        }
      }
    }
  };

  useEffect(() => {
    handleApply();
  }, [TradeCategoryList]);

  const getTableData = () => {
    if (tradeSubCategoryData === undefined) {
      return { tbody: [], thead: [] };
    }
    var tableHead: Thead[] = [
      { id: "S.No", children: "S.No", text: "S.No" },
      { id: "Name", children: "Name", text: "Name" },
      { id: "Category Name", children: "Category Name", text: "Category Name" },
      { id: "Status", children: "Status", text: "Status" },
      { id: "Action", children: "Action", text: "Action" },
    ];
    var tableBody: Tbody[][] = [];
    var tableBodyFiltered: Tbody[][] = [];

    tradeSubCategoryData.forEach((item, i) => {
      let index = TradeCategoryList.findIndex((c, i) => {
        return c.id === item.catid;
      });
      var cname = TradeCategoryList[index]?.name;
      var tBody: Tbody[] = [];

      const pageNumber = Math.floor(offset / limit) + 1;

      tBody.push(
        {
          id: nanoid(),
          theadId: "S.No",
          children: limit * pageNumber + i - limit + 1,
          text: (limit * pageNumber + i - limit + 1)?.toString(),
        },
        {
          id: nanoid(),
          theadId: "Name",
          children: item.name,
          text: item.name,
          className: "text-truncate",
        },
        {
          id: nanoid(),
          theadId: "Category Name",
          children: cname,
          text: cname,
          className: "text-truncate",
        },
        {
          id: nanoid(),
          theadId: "Status",
          children: item.active ? "Active" : !item.active ? "InActive" : "",
          text: item.active ? "Active" : !item.active ? "InActive" : "",
        },
        {
          id: nanoid(),
          theadId: "Action",
          children: "action",
          className: "d-none",
          text: item.id?.toString(),
        }
      );
      tableBody.push(tBody);
      // })
    });
    if (searchTerm?.length > 0) {
      tableBodyFiltered = tableBody.filter((item) =>
        searchSubCatHandler(item, searchTerm)
      );
    } else {
      tableBodyFiltered = tableBody;
    }

    if (tradeSubCategoryData !== undefined) {
      return { tbody: tableBodyFiltered, thead: tableHead };
    }
  };

  const handleAdd = () => {
    setShowForm(true);
    if(adminAuthendicated){
      const logProps1 = {
        action: 'Create',
        event: `${pageName.pathname.replace("\\", " ")} page | Create Trade Sub Category Clicked`
      };
      AdminLog(logProps1)
    }
  };

  const handleView = () => {
    setFormType(FormTypeList.VIEW);
    if (id !== undefined) {
      var obj = TradeSubCategoryList.find((c) => c.id === id);
      setShowForm(true);
      setEditData(obj);
      setShowViewForm(true);
      if(adminAuthendicated){
        const logProps1 = {
          action: 'View',
          event: `${pageName.pathname.replace("\\", " ")} page |  ${obj?.name} TradeSubCategory Viewed | View Clicked`
        };
        AdminLog(logProps1)
      }
    }
  };

  const handleDeleteConfirmation = () => {
    setFormType(FormTypeList.DELETE);
    if (id !== undefined) {
      var obj = TradeSubCategoryList.find((c) => c.id === id);
      setShowDeleteConfirmation(true);
      setDeleteData(obj);
    }
  };

  const handleEdit = () => {
    setFormType(FormTypeList.EDIT);
    if (id !== undefined) {
      var obj = TradeSubCategoryList.find((c) => c.id === id);
      setEditData(obj);
      setShowForm(true);
      if(adminAuthendicated){
        const logProps1 = {
          action: 'Edit',
          event: `${pageName.pathname.replace("\\", " ")} page | TradeSubCategory - ${obj?.name} | Edit Clicked`
        };
        AdminLog(logProps1)
      }
    }
  };

  const handleDelete: HandleDelete = (value) => {
    if (value.id !== undefined) {
      dispatch(deleteTradeSubCategory(value.id))
        .then(async (text) => {
          dispatch(fetchTradeSubCategory());
          toast(text, ToastTypes.SUCCESS);
          if (
            tradeSubCategoryData !== undefined &&
            tradeSubCategoryData?.length === 1
          ) {
            let newOffset = offset - limit < 0 ? 0 : offset - limit;
            setOffset(newOffset);
            await handleApply(newOffset);
          } else {
            await handleApply(); //issue starts from here
          }
          handleFormClose();
        })
        .catch((text) => {});
    }
  };

  const handleFormClose = () => {
    setShowForm(false);
    setFormType(FormTypeList.ADD);
    setEditData(undefined);
    setId(undefined);
    setShowViewForm(false);
    setShowDeleteConfirmation(false);
  };

  useEffect(() => {
    if (id !== undefined && formType === FormTypeList.VIEW) handleView();
    else if (id !== undefined && formType === FormTypeList.EDIT) handleEdit();
    else if (id !== undefined && formType === FormTypeList.DELETE)
      handleDeleteConfirmation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, formType]);

  useEffect(() => {
    var range = [];
    var num = Math.ceil(length / limit);
    // let i = 1;

    for (let i = 1; i <= num; i++) {
      range.push(i);
    }

    setRange(range);
  }, [length, limit]);

  useEffect(()=>{
    if(adminAuthendicated){
    const logProps1 = {
      action:`Visit`,
      event: `${pageName.pathname.replace("\\", " ")} page | Visited `
    };
    AdminLog(logProps1)
  }
  },[])

  return (
    <>
      <Modal
        headerText={formType + " TRADE SUB CATEGORY"}
        visible={showForm}
        onClose={handleFormClose}
        size="xl"
        centered
      >
        <TradeSubCategoryForm
          formType={formType}
          editData={editData}
          onSave={handleFormClose}
          showViewForm={showViewForm}
        />
      </Modal>
      <Modal
        headerText={formType + " TRADE SUB CATEGORY"}
        visible={showDeleteConfirmation}
        onClose={handleFormClose}
        size="lg"
        centered
      >
        <DeleteConfirmationForm
          deleteLabel={
            "Do you really want to delete the records with the Trade Sub Category - " +
            deleteData?.name
          }
          onCancel={handleFormClose}
          onDelete={() => handleDelete(deleteData!)}
        />
      </Modal>
      <AdminLayout>
        {/* <PageTitle
                title='Trade Sub-Category Management'
                buttonText='Create Trade Sub-Category'
                onButtonClick={handleAdd}
            /> */}
        <AdminTable2
          addBtnText="Create New Trade Sub-Category"
          onAdd={handleAdd}
          onEdit={handleEdit}
          onDelete={handleDeleteConfirmation}
          onView={handleView}
          search={searchHandler}
          limit={limit}
          setOffset={setOffset}
          offset={offset}
          onPageNoChange={handleApply}
          range={range}
          pageTitle="Trade Sub-Category Management"
          path="/admin/trade_sub_category"
          // data={data}
          setId={setId}
          {...getTableData()}
        />
        {/* <div className="card mt-3">
                <div className="card-body">
                    <div className='row'>
                        <div className="col-lg-6 col-sm-12">
                            <h5 className="header-title mb-3 float-start"><i className="fe-align-justify me-1 text-primary"></i>Trade Sub-Category Management</h5>
                        </div>
                        <div className="col-lg-6 col-sm-12 text-end mb-1">
                            <button type="button" onClick={handleAdd} className={`me-1 btn btn-xs btn-secondary`}>Create Trade Sub-Category</button>
                        </div>
                    </div>
                    <hr className='hr mt-0' />
                    <div className='table-wrapper'>
                        <table className='table colored'>
                            <thead>
                                <tr>
                                    <th className='text-truncate align-middle'>S.No</th>
                                    <th className='text-truncate 
                                    align-middle'> Name</th>
                                    <th className='text-truncate align-middle'>Category Name</th>
                                    <th className='text-truncate align-middle'>Status</th>
                                    <th className='text-truncate align-middle'>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {TradeSubCategoryList.map((u, i) => {
                                    let index = TradeCategoryList.findIndex((c, i) => {
                                        return c.id === u.catid
                                    })
                                    var cname = TradeCategoryList[index]?.name
                                    return <TradeSubCategoryBody
                                        sno={i + 1}
                                        name={u.name}
                                        catid={u.catid}
                                        categoryname={cname}
                                        active={u.active}
                                        key={i}
                                        onEdit={() => handleEdit(u)}
                                        onDelete={() => handleDelete(u)}
                                        onView={() => handleView(u)}
                                    />
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div> */}
      </AdminLayout>
    </>
  );
};

// const TradeSubCategoryBody = ({ sno, name, catid, active, categoryname, onView, onEdit, onDelete }: TableBody) => {
//     return <tr>
//         <td className='align-middle '>{sno}</td>
//         <td className='align-middle'>{name}</td>
//         <td className='align-middle'>{categoryname}</td>
//         <td className='align-middle'>{active ? "Active" : "InActive"}</td>
//         <td>
//             <div className='hstack gap-1'>
//                 <button className='btn btn-sm btn-secondary' onClick={onEdit}>Edit</button>
//                 <button className='btn btn-sm btn-secondary' onClick={onDelete}>Delete</button>
//                 <button className='btn btn-sm btn-secondary' onClick={onView}>View</button>
//             </div>
//         </td>
//     </tr>
// }

export default TradeSubCategory;
