import React, { ChangeEventHandler, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  BuildingType,
  fetchBuilding,
} from "../../redux/actions/buildingActions";
import { FloorType, fetchFloor } from "../../redux/actions/floorActions";
import { PaxAreaTypes } from "../../redux/actions/leaseInfoActions";
import { SiteType } from "../../redux/actions/siteActions";
import { AppDispatch, StoreState } from "../../redux/store";
import Option from "../Option";
import {
  TenantManagerContext,
  TenantManagerContextType,
} from "../context/TenantManagerContext";
import { FormInput } from "../custom/components";
import { testNumber } from "../../config/functions";

// const BUILDINGTERMINAL_DROPS = [
//     {
//         text: 'Shopping Center',
//         value: 'Shopping Center'
//     },
//     {
//         text: 'Mall',
//         value: 'Mall'
//     },
// ]

const PAXTYPE_DROPS = [
  {
    text: "Arrival",
    value: "Arrival",
  },
  {
    text: "Departures",
    value: "Departures",
  },
  {
    text: "Others",
    value: "Others",
  },
];

const LeaseInfoForm = () => {
  const dispatch = useDispatch<AppDispatch>();
  const {
    centerId,
    centerIdError,
    setCenterIdError,
    portfolioId,
    setCenterId,
    leaseid,
    setLeaseId,
    carpetArea,
    setCarpetArea,
    chargeableArea,
    setChargeableArea,
    landmark,
    setLandmark,
    buildingTerminal,
    setBuildingTerminal,
    location,
    setLocation,
    paxType,
    setPaxType,
    unitName,
    setUnitName,
    leaseIdError,
    setLeaseIdError,
    carpetAreaError,
    setCarpetAreaError,
    leasableAreaError,
    setLeasableAreaError,
    landmarkError,
    setLandmarkError,
    buildingTerminalError,
    setBuildingTerminalError,
    locationError,
    setLocationError,
    paxTypeError,
    setPaxTypeError,
    unitNameError,
    setUnitNameError,
  } = React.useContext<TenantManagerContextType | null>(TenantManagerContext)!;

  const buildingList = useSelector<StoreState, Array<BuildingType>>(
    (state) => state.building
  );
  const FloorList = useSelector<StoreState, Array<FloorType>>(
    (state) => state.floor
  );
  const siteList = useSelector<StoreState, Array<SiteType>>(
    (state) => state.site
  ).filter((s) => s.pfid?.toString() === portfolioId);

  // const [carpetArea, setCarpetArea] = useState<string>('')
  // const [carpetAreaError, setCarpetAreaError] = useState<string>('')
  // const [leasableArea, setLeasableArea] = useState<string>('')
  // const [leasableAreaError, setLeasableAreaError] = useState<string>('')
  // const [landmark, setLandmark] = useState<string>('')
  // const [landmarkError, setLandmarkError] = useState<string>('')
  // const [buildingTerminal, setBuildingTerminal] = useState<string>('')
  // const [buildingTerminalError, setBuildingTerminalError] = useState<string>('')
  // const [location, setLocation] = useState<string>('')
  // const [locationError, setLocationError] = useState<string>('')
  // const [paxType, setPaxType] = useState<PaxTypeTypes>(PaxTypeDropDown.Arrival)
  // const [paxTypeError, setPaxTypeError] = useState<string>('')
  // const [unitName, setUnitName] = useState<string>('')
  // const [unitNameError, setUnitNameError] = useState<string>('')

  const filteredBuildings = useMemo<BuildingType[]>(() => {
    if (centerId) {
      return buildingList.filter((s) => s.centerid?.toString() === centerId);
    } else return buildingList;
  }, [centerId, buildingList]);

  const filteredFloors = useMemo<FloorType[]>(() => {
    if (buildingTerminal) {
      return FloorList.filter(
        (s) => s.buildingid?.toString() === buildingTerminal
      );
    } else return FloorList;
  }, [buildingTerminal, FloorList]);

  const handleLeaseId: ChangeEventHandler<HTMLInputElement> = (e) => {
    setLeaseId!(e.target.value);
    setLeaseIdError!("");
  };

  const handleCarpetArea: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (!testNumber(e.target.value) && e.target.value) return;

    setCarpetArea!(e.target.value);
    setCarpetAreaError!("");
  };
  const handleLeasableArea: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (!testNumber(e.target.value) && e.target.value) return;

    setChargeableArea!(e.target.value);
    setLeasableAreaError!("");
  };
  const handleLandmark: ChangeEventHandler<HTMLInputElement> = (e) => {
    setLandmark!(e.target.value);
    setLandmarkError!("");
  };

  const handleBuildingTerminal: ChangeEventHandler<HTMLInputElement> = (e) => {
    setBuildingTerminal!(e.target.value);
    setBuildingTerminalError!("");
  };

  const handleLocation: ChangeEventHandler<HTMLInputElement> = (e) => {
    setLocation!(e.target.value);
    setLocationError!("");
  };

  const handleCenterId: ChangeEventHandler<HTMLInputElement> = (e) => {
    setCenterId!(e.target.value);
    setCenterIdError!("");
  };

  const handlePaxType: ChangeEventHandler<HTMLInputElement> = (e) => {
    setPaxType!(e.target.value as PaxAreaTypes);
    setPaxTypeError!("");
  };

  const handleUnitName: ChangeEventHandler<HTMLInputElement> = (e) => {
    setUnitName!(e.target.value);
    setUnitNameError!("");
  };

  useEffect(() => {
    if (portfolioId) {
      dispatch(fetchFloor(portfolioId));
      dispatch(fetchBuilding(portfolioId));
    }
  }, [portfolioId]);

  return (
    <div className="container">
      <h4>Tenant Lease Information</h4>
      <hr />
      <div className="row">
        <div className="col-sm-6 col-md-4 col-lg-3">
          <FormInput
            name="Lease Id"
            label="Lease Id"
            labelClassName="required"
            value={leaseid}
            onChange={handleLeaseId}
            placeholder="Lease Id"
            errorText={leaseIdError}
            containerClass="mb-2"
            type="text"
          />
        </div>

        <div className="col-sm-6 col-md-4 col-lg-3">
          <FormInput
            name="Carpet Area"
            label="Carpet Area"
            labelClassName="required"
            value={carpetArea}
            onChange={handleCarpetArea}
            placeholder="No. of Carpet Area"
            errorText={carpetAreaError}
            containerClass="mb-2"
            type="text"
          />
        </div>

        <div className="col-sm-6 col-md-4 col-lg-3">
          <FormInput
            name="Chargeable Area"
            label="Chargeable Area"
            labelClassName="required"
            value={chargeableArea}
            onChange={handleLeasableArea}
            placeholder="No. of Chargeable Area"
            errorText={leasableAreaError}
            containerClass="mb-2"
            type="text"
          />
        </div>

        <div className="col-sm-6 col-md-4 col-lg-3">
          <FormInput
            name="Landmark"
            label="Landmark"
            labelClassName="required"
            value={landmark}
            onChange={handleLandmark}
            placeholder="Landmark"
            errorText={landmarkError}
            containerClass="mb-2"
          />
        </div>

        <div className="col-sm-6 col-md-4 col-lg-3">
          <FormInput
            name="Select a center"
            label="Center"
            labelClassName="required"
            value={centerId}
            onChange={handleCenterId}
            placeholder="Center"
            errorText={centerIdError}
            containerClass="mb-2"
            type="select"
          >
            <option value="">Select</option>
            {siteList.map((a, i) => {
              return (
                <Option value={a.id} key={i}>
                  {a.name}
                </Option>
              );
            })}
          </FormInput>
        </div>

        <div className="col-sm-6 col-md-4 col-lg-3">
          <FormInput
            name="Building/Terminal"
            label="Building/Terminal"
            labelClassName="required"
            value={buildingTerminal}
            onChange={handleBuildingTerminal}
            placeholder="Building/Terminal"
            errorText={buildingTerminalError}
            containerClass="mb-2"
            type="select"
          >
            <option value="">Select</option>
            {filteredBuildings.map((a, i) => {
              return (
                <Option value={a.id} key={i}>
                  {a.buildingname}
                </Option>
              );
            })}
          </FormInput>
        </div>

        <div className="col-sm-6 col-md-4 col-lg-3">
          <FormInput
            name="Location"
            label="Location"
            labelClassName="required"
            value={location}
            onChange={handleLocation}
            placeholder="Location"
            errorText={locationError}
            containerClass="mb-2"
            type="select"
          >
            <option value="">Select</option>
            {filteredFloors.map((a, i) => {
              return (
                <Option value={a.id} key={i}>
                  {a.floorname}
                </Option>
              );
            })}
          </FormInput>
        </div>

        <div className="col-sm-6 col-md-4 col-lg-3">
          <FormInput
            name="PAX Area"
            label="PAX Area"
            labelClassName="required"
            value={paxType}
            onChange={handlePaxType}
            placeholder="PAX Area"
            errorText={paxTypeError}
            containerClass="mb-2"
            type="select"
          >
            <option value="">Select</option>
            {PAXTYPE_DROPS.map((ud, i) => {
              return (
                <Option value={ud.value} key={i}>
                  {ud.text}
                </Option>
              );
            })}
          </FormInput>
        </div>

        <div className="col-sm-6 col-md-4 col-lg-3">
          <FormInput
            name="Unit Name"
            label="Unit Name"
            labelClassName="required"
            value={unitName}
            onChange={handleUnitName}
            placeholder="Unit Name"
            errorText={unitNameError}
            containerClass="mb-2"
          />
        </div>
      </div>
    </div>
  );
};
export default LeaseInfoForm;
